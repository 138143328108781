import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { Route, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { COMMONTEXT } from '../../app.constants';
import { UserManagementService } from '../../services/user-management/user-management.service'
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  logout = false;
  unauthorized = false;
  popupOpened = false;
  unAuthpopupOpened = false;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private userManagementService: UserManagementService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.sharedService.showLoader();
    const started = Date.now();
    const userToken = sessionStorage.getItem('Token');
    const modifiedReq = req.clone({
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   // 'Authorization': userToken || '',
      //   // 'Access-Control-Allow-Origin': "Origin",
      //   'Cache-Control': 'no-cache',
      //   Pragma: 'no-cache'
      // })
    });
    // const token: string = localStorage.getItem('token');

    // if (token) {
    //   req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    // }
    return next.handle(modifiedReq).pipe(
      tap(event => {
        // console.log('EVENT : ', event)
        // this.sharedService.showLoader();
        if (event instanceof HttpResponse) {
          this.sharedService.setApiStatus(true)
          console.log("SEtting Api check to true");
          this.sharedService.hideLoader();
          // console.log('EVENT : ', event)
        }
      }, err => {
        this.sharedService.hideLoader();
        if (err instanceof HttpErrorResponse) {
          this.sharedService.setApiStatus(false)
          console.log("SEtting Api check to false")
          // console.log('err ', err)
          if (err.status === 403) {
            // this is where you can do anything like navigating
            // this.sharedService.showError(err.statusText);
            this.router.navigateByUrl('/login');
          } else if (err.status == 409) {
            this.logout = true;
            // this.sharedService.confirm(COMMONTEXT.CONFIRMATION_HEADER, 'User login from different browser', 'md').then((res: any) => {
            //   this.router.navigateByUrl('/Login');
            // })
          } else if (err.status == 401) {
            this.unauthorized = true;
          }
          if (this.logout == true) {
            this.popupOpened = true;
            this.router.navigateByUrl('/Login');
          }

          if (this.unauthorized == true ) {
            this.router.navigateByUrl('/Login');
          }
        }
        // console.error('NICE ERROR', error)
      })
    )

    // return next.handle(req).pipe(res => this.sharedService.hideLoader());
  }
}