import {Component, ViewChild, OnInit, AfterViewInit, Compiler} from "@angular/core";
import {Event} from "@angular/router";
import {SharedService} from "../../services/shared/shared.service";
import {Router, ActivatedRoute, ParamMap, NavigationExtras} from "@angular/router";
import {DashboardService} from "../../services/dashboard-service/dashboard-service.component";
import {ModalDirective} from "ngx-bootstrap/modal";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {COMMONTEXT} from "../../app.constants";
import {environment} from "src/environments/environment";
import {CommonService} from "../../services/common/common.service";
import {UserManagementService} from "../../services/user-management/user-management.service";
import {ApplicationService} from "../../services/application/application.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
    selector: "app-side-bar",
    templateUrl: "./side-bar.component.html",
    styleUrls: ["./side-bar.component.css"],
})
export class SideBarComponent implements AfterViewInit, OnInit {
    applicantCategory: FormGroup;
    userDetails;
    logViewDetails;
    enableAlert = false;
    applicationDetailsArray1;
    applicationDetailsArray2;
    enableSecondUploadDpr;
    enableSecondOnlineFiling;
    sHGApplicationStatus;
    fpcApplicationStatus;
    getApplicantIDDetails;
    coopApplicationStatus;
    comInfApplicationStatus;
    applicationDetailsArray;
    hideTab = false;
    widthdrawForm: FormGroup;
    formSubmittedWid = false;
    appDetailsWid;
    enableRecaptcha = false;
    formSubmitted = false;
    applicantsType = [];
    addedMmber: any = [];
    nonIndividualValue;
    typeNonInd = false;
    shgMemberDetails: any = [];
    enableCommonInfra = false;
    subCategoryList = [];
    href: any;
    addArraySHG = [];
    subCategoryValue;
    page = 1;
    count = 0;
    tableSize = 5;
    size = 5;
    term:any = "";
    tableSizes = [5,10, 15, 20, 25];
    addedMemberCode: any;

    tableObj = {
        tableData: [],
        tableHeader: [],
        list: "view-reply",
    };
    stateNRLM: any;
    XTenantIdentifier: any;
    districtNRLM: any;
    blockNRLM: any;
    @ViewChild("shgNRLM") public shgNRLM: ModalDirective;
    @ViewChild("shgNRLMMore") public shgNRLMMore: ModalDirective;
    @ViewChild("shgNRLMCommonInfro") public shgNRLMCommonInfro: ModalDirective;
    @ViewChild("widthdrawModal") public widthdrawModal: ModalDirective;
    @ViewChild("forTrainingUser") public forTrainingUser: ModalDirective;
    @ViewChild("statusModal") public statusModal: ModalDirective;
    private dummyResponse: {
        responseObject: {
            action: null;
            additionalDocumentsResponse: (
                | {
                      userFor: null;
                      requiredType: string;
                      otherDetails: null;
                      usedFor: string;
                      docCode: number;
                      description: string;
                      status: number;
                  }
                | {
                      userFor: null;
                      requiredType: string;
                      otherDetails: null;
                      usedFor: string;
                      docCode: number;
                      description: string;
                      status: number;
                  }
                | {
                      userFor: null;
                      requiredType: string;
                      otherDetails: null;
                      usedFor: string;
                      docCode: number;
                      description: string;
                      status: number;
                  }
            )[];
            applicationId: number;
            remarks: null;
            status: null;
        };
        responseDesc: string;
        responseCode: number;
    };
    public additionDocumentDetail = false;
    public sanctionApplications: any = false;
    userDetail: any;
    shgDetails: any = [];
    constructor(
        // private event: Event,
        private sharedService: SharedService,
        private router: Router,
        private dashboardService: DashboardService,
        private commonService: CommonService,
        private _compiler: Compiler,
        private userManagementService: UserManagementService,
        private applicationService: ApplicationService,
        private http: HttpClient
    ) {}

    ngOnInit() {
        if (environment.baseUrl != ("https://pmfme.mofpi.gov.in/mofpi/" && "http://10.153.166.2/pmfme/")) {
            this.enableRecaptcha = true;
        } else {
            this.enableRecaptcha = false;
        }

        this.widthdrawForm = new FormGroup({
            widthdrawReason: new FormControl("", Validators.required),
        });

        this.applicantCategory = new FormGroup({
            category: new FormControl("", Validators.required),
        });
        this.getApplicationDetails();
        this.getSanctionedApplications();
    }

    ngOnChanges() {}

    ngAfterViewInit() {
        this.getUserDetails();
    }

    getUserDetails() {
        this.sharedService.getLocalStorageItem("userDetails").then((resp: any) => {
            if (resp) {
                this.userDetails = resp;
                console.log("this.userDetailsAAAA : ", this.userDetails);

                if (this.userDetails.subCategory) {
                    if (
                        this.userDetails.subCategory.applicantSubCategory == 13 ||
                        this.userDetails.subCategory.applicantSubCategory == 14 ||
                        this.userDetails.subCategory.applicantSubCategory == 7 ||
                        this.userDetails.subCategory.applicantSubCategory == 8 ||
                        this.userDetails.subCategory.applicantSubCategory == 9 ||
                        this.userDetails.subCategory.applicantSubCategory == 10 ||
                        this.userDetails.subCategory.applicantSubCategory == 11 ||
                        this.userDetails.subCategory.applicantSubCategory == 12 ||
                        this.userDetails.subCategory.applicantSubCategory == 15
                    ) {
                        this.hideTab = true;
                    } else {
                        this.hideTab = false;
                    }
                }
            }
        });
    }

    onTableDataChange(event) {
        console.log("Event ", event);
        console.log("this.searchTrainingList.length ", this.tableObj.tableData.length);
        console.log("this.tableSize ", this.tableSize);
        if (
            event === this.tableObj.tableData.length / this.tableSize ||
            event > this.tableObj.tableData.length / this.tableSize
        ) {
            this.page = event;
            this.sharedService.getLocalStorageItem("pageTrainingCreation").then((page: any) => {
                this.sharedService.setLocalStorageItem("pageTrainingCreation", page + 1);
            });
            // this.searchDetails();
        } else {
            this.page = event;
        }
    }

    onTableSizeChange(event): void {
        this.tableObj.tableData = [];

        console.log("event.target.value ", event.target.value);
        this.tableSize = event.target.value;
        this.page = 1;

        this.sharedService.setLocalStorageItem("pageTrainingCreation", 0);
        // this.searchDetails();
    }

    restrictRouting() {
        this.sharedService.getLocalStorageItem("userDetails").then((resp: any) => {
            this.userDetails = resp;
            if (this.userDetails.sponsorAgencyFlag != null) {
                if (
                    this.userDetails.sponsorAgencyFlag.trim() == "Y" &&
                    this.userDetails.sponsorAgencyName.trim() == "1"
                ) {
                    this.dashboardService.getApplicationDetailsNRLMInd().then((resp: any) => {
                      
                        if (resp.body.responseCode == 404) {
                            this.shgNRLM.show();
                            this.getNrlmListData();
                        } else if (resp.body.responseCode == 200) {
                            const resObj = JSON.parse(this.sharedService.aesDecryption(resp["body"]["responseObject"]));
                            if (resObj.applications[0].statusNo == 22) {
                                this.shgNRLM.show();
                                this.getNrlmListData();
                            } else {
                                this.getApplicationStatus();
                            }
                        } else {
                            this.getApplicationStatus();
                        }
                    });
                    // if (this.sharedService.getLocalStorageItem('getApplicantIDDetails') == null || this.sharedService.getLocalStorageItem('getApplicantIDDetails') == undefined ) {

                    // }else{
                    //     this.getShgApplicantId();

                    // }
                } else {
                    this.getApplicationStatus();
                    // this.showTabNRLM = false;
                }
            } else {
                this.getApplicationStatus();
            }

            // console.log(" this.showTabNRLM ", this.showTabNRLM );
        });

        // this.getApplicationStatus();
        //console.log('this.applicationDetailsArrayyyyy1 : ', this.applicationDetailsArray1)
    }

    // getApplicationStatus() {

    //   this.sharedService.getLocalStorageItem('applicationDetailsArray').then((resp: any) => {

    //     if(this.applicationDetailsArray1 == undefined){

    //       this.dashboardService.getApplicationDetails().then((resp: any) => {
    //         if (resp.status == 200) {
    //           if (resp['body']['responseCode'] == 200) {

    //             this.applicationDetailsArray1 = resp['body']['responseObject'].applications[0];
    //             this.sharedService.setLocalStorage('applicationDetailsArray', JSON.stringify(this.applicationDetailsArray1));

    //             if (this.applicationDetailsArray1) {
    //               if (this.userDetails.subCategory.applicantSubCategory == 4 && this.applicationDetailsArray1.statusNo == 2) {
    //                 this.router.navigateByUrl('/Basic-Application-Details');
    //               }
    //                else {

    //                 if (this.applicationDetailsArray1.statusNo != 2 && this.userDetails.subCategory.applicantSubCategory == 4) {
    //                   this.enableAlert = true;
    //                  }
    //               }
    //             }else {
    //               if (this.userDetails.subCategory.applicantSubCategory == 4) {
    //                 this.router.navigateByUrl('/Basic-Application-Details');
    //               }
    //             }

    //           }else {
    //             if (this.userDetails.subCategory.applicantSubCategory == 4) {
    //               this.router.navigateByUrl('/Basic-Application-Details');
    //             }
    //           }

    //         }

    //       }, (error) => {
    //         // this.sharedService.showWarning(COMMONTEXT.API_ERROR)
    //       })

    //     }else{
    //       this.applicationDetailsArray1 = resp
    //       if (this.userDetails.subCategory.applicantSubCategory == 4 && this.applicationDetailsArray1.statusNo == 2) {
    //         this.router.navigateByUrl('/Basic-Application-Details');
    //       }
    //        else {

    //         if (this.applicationDetailsArray1.statusNo != 2 && this.userDetails.subCategory.applicantSubCategory == 4) {
    //           this.enableAlert = true;
    //          }
    //       }
    //     }

    //   })

    // }

    // I HAVE MADE NEW METHOD TO REMOVE THE OTHER API'S FROM LOGIN, IF ANY ISSUE OCCURS KINLDY REMOVE ABOVE METHOD AND UNCOMMENT BELOW METHOD

    // getApplicationStatus() {
    //   this.sharedService.getLocalStorageItem('applicationDetailsArray').then((resp: any) => {
    //     console.log('RESPPPP : ', resp)
    //     if (resp) {
    //       this.applicationDetailsArray1 = resp;
    //       console.log('this.applicationDetailsArrayyyyy : ', this.applicationDetailsArray1)

    //       if (this.applicationDetailsArray1) {
    //         if (this.userDetails.subCategory.applicantSubCategory == 4 && (this.applicationDetailsArray1.statusNo == 2 || this.applicationDetailsArray1.statusNo == 6)) {
    //           this.router.navigateByUrl('/Basic-Application-Details');
    //         }
    //         else {
    //           if (this.userDetails.subCategory.applicantSubCategory == 4 && (this.applicationDetailsArray1.statusNo != 2 || this.applicationDetailsArray1.statusNo != 6)) {
    //             this.enableAlert = true;
    //           }
    //         }
    //       }
    //     } else {
    //       if (this.userDetails.subCategory.applicantSubCategory == 4) {
    //         this.router.navigateByUrl('/Basic-Application-Details');
    //       }
    //     }
    //   })
    // }

    getApplicationStatus() {
        this.dashboardService.getApplicationDetails().then((resp: any) => {
          
            if (resp.status == 200) {
                if (resp["body"]["responseCode"] == 200) {
                    if (resp["body"].responseObject) {
                        // this.applicationDetailsArray1 = resp['body']['responseObject'].applications[0];
                        // this.applicationDetailsArray1 = resp['body']['responseObject'].applications[0];

                        const respObj = JSON.parse(this.sharedService.aesDecryption(resp["body"]["responseObject"]));

                        this.applicationDetailsArray1 = respObj.applications[0];

                        if (this.applicationDetailsArray1) {
                            // this.sharedService.setLocalStorage('applicationDetailsArray', JSON.stringify(this.applicationDetailsArray1));
                            if (
                                this.applicationDetailsArray1.statusNo == 22 ||
                                this.applicationDetailsArray1.statusNo == 11 ||
                                this.applicationDetailsArray1.statusNo == 7
                            ) {
                                this.sharedService.clearLocalStorageItem("applicationDetailsArray");
                            } else {
                                this.sharedService.setLocalStorage(
                                    "applicationDetailsArray",
                                    JSON.stringify(this.applicationDetailsArray1)
                                );
                            }
                            console.log(
                                "this.userDetails.subCategory.applicantSubCategory : ",
                                this.userDetails.subCategory.applicantSubCategory
                            );
                            console.log(
                                "this.userDetails.subCategory.applicantSubCategory : ",
                                this.applicationDetailsArray1.statusNo
                            );

                            if (
                                this.userDetails.subCategory.applicantSubCategory == 4 &&
                                (this.applicationDetailsArray1.statusNo == 2 ||
                                    this.applicationDetailsArray1.statusNo == 6 ||
                                    this.applicationDetailsArray1.statusNo == 22 ||
                                    this.applicationDetailsArray1.statusNo == 11 ||
                                    this.applicationDetailsArray1.statusNo == 7)
                            ) {
                                this.href = this.router.url;
                                console.log("this.href", this.href);
                                if (this.href == "/Basic-Application-Details") {
                                    window.location.reload();
                                    this.router.navigateByUrl("/Basic-Application-Details");
                                } else {
                                    this.router.navigateByUrl("/Basic-Application-Details");
                                    console.log("1");
                                }
                            } else {
                                if (
                                    this.userDetails.subCategory.applicantSubCategory == 4 &&
                                    (this.applicationDetailsArray1.statusNo != 2 ||
                                        this.applicationDetailsArray1.statusNo != 6 ||
                                        this.applicationDetailsArray1.statusNo != 22 ||
                                        this.applicationDetailsArray1.statusNo != 7)
                                ) {
                                    this.enableAlert = true;
                                }
                            }
                        }
                    } else {
                        if (this.userDetails.subCategory.applicantSubCategory == 4) {
                            this.href = this.router.url;
                            if (this.href == "/Basic-Application-Details") {
                                window.location.reload();
                                this.router.navigateByUrl("/Basic-Application-Details");
                            } else {
                                this.router.navigateByUrl("/Basic-Application-Details");
                            }
                        }
                    }
                } else {
                    if (this.userDetails.subCategory.applicantSubCategory == 4) {
                        this.href = this.router.url;
                        if (this.href == "/Basic-Application-Details") {
                            window.location.reload();
                            this.router.navigateByUrl("/Basic-Application-Details");
                        } else {
                            this.router.navigateByUrl("/Basic-Application-Details");
                        }
                    }
                }
            } else {
                if (this.userDetails.subCategory.applicantSubCategory == 4) {
                    this.router.navigateByUrl("/Basic-Application-Details");
                }
            }
        });
    }

    async getNrlmListData() {
        await this.sharedService.getLocalStorageItem("userDetails").then((resp: any) => {
            if (resp) {
                
                this.userDetails = resp;
                // let body = new URLSearchParams();
                // body.set("grant_type", "client_credentials");
                // body.set("client_id", "MOFPI");
                // body.set("client_secret", "1f8eDv2KlxiF9PYZ4mdR0nqercrtzd8O");

                // let options = {
                //     headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded"),
                // };
                // this.http
                // .post("https://accounts.lokos.in/realms/lokos/protocol/openid-connect/token", body.toString(), options)
                // .subscribe((resp) => {
                //     console.log("mmmmmmm", resp["access_token"]);
                    // this.sharedService.setLocalStorage('NRLMToken', JSON.stringify(response));

                   
                    // this.sharedService.getLocalStorageItem('NRLMToken').then((resp: any) => {
                    //     console.log('Bearer '+`${resp.access_token}`)

                    // switch(this.stateNRLM) {
                    //     case "21":
                    //         this.XTenantIdentifier = "dynamic_lokos_or";
                    //         break;
                    //     case "13":
                    //         this.XTenantIdentifier = "dynamic_lokos_ng";
                    //         break;
                    //     case "15":
                    //         this.XTenantIdentifier = "dynamic_lokos_mz";
                    //         break;
                    //     case "14":
                    //         this.XTenantIdentifier = "dynamic_lokos_mn";
                    //         break;
                    //     case "31":
                    //         this.XTenantIdentifier = "dynamic_lokos_la";
                    //         break;
                    //     case "27":
                    //         this.XTenantIdentifier = "dynamic_lokos_mh";
                    //         break;
                    //     case "23":
                    //         this.XTenantIdentifier = "dynamic_lokos_mp";
                    //         break;
                    //     case "32":
                    //         this.XTenantIdentifier = "dynamic_lokos_kr";
                    //         break;
                    //     case "29":
                    //         this.XTenantIdentifier = "dynamic_lokos_kn";
                    //         break;
                    //     case "1":
                    //         this.XTenantIdentifier = "dynamic_lokos_jk";
                    //         break;
                    //     case "2":
                    //         this.XTenantIdentifier = "dynamic_lokos_hp";
                    //         break;
                    //     case "6":
                    //         this.XTenantIdentifier = "dynamic_lokos_hr";
                    //         break;
                    //     case "34":
                    //         this.XTenantIdentifier = "dynamic_lokos_po";
                    //         break;
                    //     case "3":
                    //         this.XTenantIdentifier = "dynamic_lokos_pj";
                    //         break;
                    //     case "8":
                    //         this.XTenantIdentifier = "dynamic_lokos_rj";
                    //         break;
                    //     case "11":
                    //         this.XTenantIdentifier = "dynamic_lokos_sk";
                    //         break;
                    //     case "33":
                    //         this.XTenantIdentifier = "dynamic_lokos_tn";
                    //         break;
                    //     case "16":
                    //         this.XTenantIdentifier = "dynamic_lokos_tr";
                    //         break;
                    //     case "9":
                    //         this.XTenantIdentifier = "dynamic_lokos_up";
                    //         break;
                    //     case "19":
                    //         this.XTenantIdentifier = "dynamic_lokos_wb";
                    //         break;
                    //     case "22":
                    //         this.XTenantIdentifier = "dynamic_lokos_cg";
                    //         break;
                    //     case "20":
                    //         this.XTenantIdentifier = "dynamic_lokos_jh";
                    //         break;
                    //     case "5":
                    //         this.XTenantIdentifier = "dynamic_lokos_ut";
                    //         break;
                    //     case "36":
                    //         this.XTenantIdentifier = "dynamic_lokos_ts";
                    //         break;
                    //     case "37":
                    //         this.XTenantIdentifier = "dynamic_lokos_ld";
                    //         break;
                    //     case "38":
                    //         this.XTenantIdentifier = "dynamic_lokos_dn";
                    //         break;
                    //     case "35":
                    //         this.XTenantIdentifier = "dynamic_lokos_an";
                    //         break;
                    //     case "28":
                    //         this.XTenantIdentifier = "dynamic_lokos_ap";
                    //         break;
                    //     case "12":
                    //         this.XTenantIdentifier = "dynamic_lokos_ar";
                    //         break;
                    //     case "10":
                    //         this.XTenantIdentifier = "dynamic_lokos_bh";
                    //         break;
                    //     case "4":
                    //         this.XTenantIdentifier = "dynamic_lokos_ch";
                    //         break;
                    //     case "7":
                    //         this.XTenantIdentifier = "dynamic_lokos_dl";
                    //         break;
                    //     case "18":
                    //         this.XTenantIdentifier = "dynamic_lokos_as";
                    //         break;
                    //     case "17":
                    //         this.XTenantIdentifier = "dynamic_lokos_mg";
                    //         break;
                    // }
                    // const headers = {
                    //     headers: new HttpHeaders({
                    //         Authorization: "Bearer " + `${resp["access_token"]}`,
                    //         "X-Tenant-Identifier": `${this.XTenantIdentifier}`,
                    //     }),
                    // };
                    this.stateNRLM = this.userDetails.state.stateCode.trim();
                    this.districtNRLM = this.userDetails.district.districtCode.trim();
                    this.blockNRLM = this.userDetails.block.blockCode.trim();
                    let postData = 
                        {
                            "stateId":this.stateNRLM,
                            "districtId":this.districtNRLM,
                            "blockId":this.blockNRLM,
                            "page":0,
                            "size":100000
                        }
                    
                    this.sharedService.showLoader();
                    // this.commonService.GetShgListByBlockId(postData).then((data)
                    // this.http.post('https://pmfme.mofpi.gov.in/externalServices/api/shg/getShgListByBlockId',postData)
                    this.http.post('https://pmfme.mofpi.gov.in/externalServices/api/shg/getShgListByBlockId',postData).subscribe((data) => {
                        console.log(data);
                        if (data["responseCode"] == 200 || data["responseCode"] == "200") {
                            if (data["responseData"] !== null) {
                               
                                this.sharedService.hideLoader();
                                this.shgDetails = data["responseData"].content;
                                console.log("shg details", data["responseData"]);
                            }
                        }
                    });
                // });
            }
        });
    }

    async getMemberDetail(code) {
        // let body = new URLSearchParams();
        // body.set("grant_type", "client_credentials");
        // body.set("client_id", "MOFPI");
        // body.set("client_secret", "1f8eDv2KlxiF9PYZ4mdR0nqercrtzd8O");

        // let options = {
        //     headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded"),
        // };

        // console.log("mmmmmmm",this.stateNRLM)
        // switch (this.stateNRLM) {
        //     case "21":
        //         this.XTenantIdentifier = "dynamic_lokos_or";
        //         break;
        //     case "13":
        //         this.XTenantIdentifier = "dynamic_lokos_ng";
        //         break;
        //     case "15":
        //         this.XTenantIdentifier = "dynamic_lokos_mz";
        //         break;
        //     case "14":
        //         this.XTenantIdentifier = "dynamic_lokos_mn";
        //         break;
        //     case "31":
        //         this.XTenantIdentifier = "dynamic_lokos_la";
        //         break;
        //     case "27":
        //         this.XTenantIdentifier = "dynamic_lokos_mh";
        //         break;
        //     case "23":
        //         this.XTenantIdentifier = "dynamic_lokos_mp";
        //         break;
        //     case "32":
        //         this.XTenantIdentifier = "dynamic_lokos_kr";
        //         break;
        //     case "29":
        //         this.XTenantIdentifier = "dynamic_lokos_kn";
        //         break;
        //     case "1":
        //         this.XTenantIdentifier = "dynamic_lokos_jk";
        //         break;
        //     case "2":
        //         this.XTenantIdentifier = "dynamic_lokos_hp";
        //         break;
        //     case "6":
        //         this.XTenantIdentifier = "dynamic_lokos_hr";
        //         break;
        //     case "34":
        //         this.XTenantIdentifier = "dynamic_lokos_po";
        //         break;
        //     case "3":
        //         this.XTenantIdentifier = "dynamic_lokos_pj";
        //         break;
        //     case "8":
        //         this.XTenantIdentifier = "dynamic_lokos_rj";
        //         break;
        //     case "11":
        //         this.XTenantIdentifier = "dynamic_lokos_sk";
        //         break;
        //     case "33":
        //         this.XTenantIdentifier = "dynamic_lokos_tn";
        //         break;
        //     case "16":
        //         this.XTenantIdentifier = "dynamic_lokos_tr";
        //         break;
        //     case "9":
        //         this.XTenantIdentifier = "dynamic_lokos_up";
        //         break;
        //     case "19":
        //         this.XTenantIdentifier = "dynamic_lokos_wb";
        //         break;
        //     case "22":
        //         this.XTenantIdentifier = "dynamic_lokos_cg";
        //         break;
        //     case "20":
        //         this.XTenantIdentifier = "dynamic_lokos_jh";
        //         break;
        //     case "5":
        //         this.XTenantIdentifier = "dynamic_lokos_ut";
        //         break;
        //     case "36":
        //         this.XTenantIdentifier = "dynamic_lokos_ts";
        //         break;
        //     case "37":
        //         this.XTenantIdentifier = "dynamic_lokos_ld";
        //         break;
        //     case "38":
        //         this.XTenantIdentifier = "dynamic_lokos_dn";
        //         break;
        //     case "35":
        //         this.XTenantIdentifier = "dynamic_lokos_an";
        //         break;
        //     case "28":
        //         this.XTenantIdentifier = "dynamic_lokos_ap";
        //         break;
        //     case "12":
        //         this.XTenantIdentifier = "dynamic_lokos_ar";
        //         break;
        //     case "10":
        //         this.XTenantIdentifier = "dynamic_lokos_bh";
        //         break;
        //     case "4":
        //         this.XTenantIdentifier = "dynamic_lokos_ch";
        //         break;
        //     case "7":
        //         this.XTenantIdentifier = "dynamic_lokos_dl";
        //         break;
        //     case "18":
        //         this.XTenantIdentifier = "dynamic_lokos_as";
        //         break;
        //     case "17":
        //         this.XTenantIdentifier = "dynamic_lokos_mg";
        //         break;
        // }
        // await this.http
        // .post("https://accounts.lokos.in/realms/lokos/protocol/openid-connect/token", body.toString(), options)
        // .subscribe((resp) => {
        //     const headers = {
        //         headers: new HttpHeaders({
        //             Authorization: "Bearer " + `${resp["access_token"]}`,
        //             "X-Tenant-Identifier": `${this.XTenantIdentifier}`,
        //         }),
        //     };
        let postData=
            {
                "shgCode":code,
                'stateId':this.stateNRLM,
                
                "page":0,
                "size":100000
            } 
        console.log("this.stateNRLM",this.stateNRLM)
            this.http.post('https://pmfme.mofpi.gov.in/externalServices/api/shg/getMemberDetailsByShgCode',postData).subscribe((data) => {
                if (data["responseCode"] == '200') {
                    const resp = data["responseData"];
                    const filteredPeople = resp.filter((item) => item.socialCategory !== 'Others');
                    this.sharedService.setLocalStorage("shgMemberDetails", JSON.stringify(filteredPeople));
                    // this.shgMemberDetails
                }
            });
        // });
    }

    restrictRoutingDPR() {
        this.getApplicationStatus1();
    }

    getApplicationStatus1() {
        this.sharedService.getLocalStorageItem("applicationDetailsArray").then((resp: any) => {
            //console.log('RESPPPP : ', resp)
            if (resp) {
                this.applicationDetailsArray2 = resp;
                //console.log('this.applicationDetailsArrayyyyy : ', this.applicationDetailsArray2)
                if (this.applicationDetailsArray2) {
                    if (this.applicationDetailsArray2.statusNo != 2) {
                        this.enableAlert = true;
                    } else {
                        if (this.userDetails.subCategory.applicantSubCategory == 4) {
                            this.router.navigateByUrl("/Apply-Individual");
                        }
                        if (this.userDetails.subCategory.applicantSubCategory == 5) {
                            this.router.navigateByUrl("/Apply-Individual");
                        }
                        if (this.userDetails.subCategory.applicantSubCategory == 6) {
                            this.router.navigateByUrl("/Apply-Individual");
                        }
                    }
                }
            } else {
                if (this.userDetails.subCategory.applicantSubCategory == 4) {
                    this.router.navigateByUrl("/Apply-Individual");
                }
                if (this.userDetails.subCategory.applicantSubCategory == 5) {
                    this.router.navigateByUrl("/Apply-Individual");
                }
                if (this.userDetails.subCategory.applicantSubCategory == 6) {
                    this.router.navigateByUrl("/Apply-Individual");
                }
            }
        });
    }

    restrictRoutingMore() {
        this.sharedService.getLocalStorageItem("userDetails").then((resp: any) => {
            this.userDetails = resp;
            if (this.userDetails.sponsorAgencyFlag != null) {
                if (
                    this.userDetails.sponsorAgencyFlag.trim() == "Y" &&
                    this.userDetails.sponsorAgencyName.trim() == "1"
                ) {
                    this.dashboardService.getApplicationDetailsNRLMInd().then((resp: any) => {
                      
                        
                        if (resp.body.responseCode == 404) {
                            this.shgNRLMMore.show();
                            this.getNrlmListData();
                        } else if (resp.body.responseCode == 200) {
                            const resObj = JSON.parse(this.sharedService.aesDecryption(resp["body"]["responseObject"]));
                            if (resObj.applications[0].statusNo == 22) {
                                this.shgNRLMMore.show();
                                this.getNrlmListData();
                            } else {
                                this.getApplicationStatus2();
                            }
                        } else {
                            this.getApplicationStatus2();
                        }
                    });
                    // if (this.sharedService.getLocalStorageItem('getApplicantIDDetails') == null || this.sharedService.getLocalStorageItem('getApplicantIDDetails') == undefined ) {

                    // }else{
                    //     this.getShgApplicantId();

                    // }
                } else {
                    this.getApplicationStatus2();
                    // this.showTabNRLM = false;
                }
            } else {
                this.getApplicationStatus2();
            }

            // console.log(" this.showTabNRLM ", this.showTabNRLM );
        });
    }

    // getApplicationStatus2() {
    //   this.sharedService.getLocalStorageItem('applicationDetailsArray').then((resp: any) => {
    //     console.log('RESPPPP : ', resp)
    //     if (resp) {
    //       this.applicationDetailsArray1 = resp;
    //       console.log('this.applicationDetailsArrayyyyy : ', this.applicationDetailsArray2)
    //       if (this.applicationDetailsArray1) {
    //         if (this.userDetails.subCategory.applicantSubCategory == 5 && (this.applicationDetailsArray1.statusNo == 2 || this.applicationDetailsArray1.statusNo == 6)) {
    //           this.router.navigateByUrl('/individual-more-than-onecrore');

    //         } else {

    //           if (this.userDetails.subCategory.applicantSubCategory == 5 && (this.applicationDetailsArray1.statusNo != 2 || this.applicationDetailsArray1.statusNo != 6)) {
    //             this.enableAlert = true;

    //           }
    //         }
    //       }

    //     } else {
    //       if (this.userDetails.subCategory.applicantSubCategory == 5) {
    //         this.router.navigateByUrl('/individual-more-than-onecrore');
    //       }
    //     }
    //   })
    // }

    getApplicationStatus2() {
        this.dashboardService.getApplicationDetails().then((resp: any) => {
            if (resp.status == 200) {
                if (resp["body"]["responseCode"] == 200) {
                    if (resp["body"].responseObject) {
                        // this.applicationDetailsArray1 = resp['body']['responseObject'].applications[0];

                        const resObj = JSON.parse(this.sharedService.aesDecryption(resp["body"]["responseObject"]));

                        this.applicationDetailsArray1 = resObj.applications[0];

                        if (this.applicationDetailsArray1) {
                            // this.sharedService.setLocalStorage('applicationDetailsArray', JSON.stringify(this.applicationDetailsArray1));
                            if (
                                this.applicationDetailsArray1.statusNo == 22 ||
                                this.applicationDetailsArray1.statusNo == 7 ||
                                this.applicationDetailsArray1.statusNo == 11
                            ) {
                                this.sharedService.clearLocalStorageItem("applicationDetailsArray");
                            } else {
                                this.sharedService.setLocalStorage(
                                    "applicationDetailsArray",
                                    JSON.stringify(this.applicationDetailsArray1)
                                );
                            }
                            if (
                                this.userDetails.subCategory.applicantSubCategory == 5 &&
                                (this.applicationDetailsArray1.statusNo == 2 ||
                                    this.applicationDetailsArray1.statusNo == 6 ||
                                    this.applicationDetailsArray1.statusNo == 7 ||
                                    this.applicationDetailsArray1.statusNo == 11 ||
                                    this.applicationDetailsArray1.statusNo == 22)
                            ) {
                                this.href = this.router.url;
                                if (this.href == "/individual-more-than-onecrore") {
                                    window.location.reload();
                                    this.router.navigateByUrl("/individual-more-than-onecrore");
                                } else {
                                    this.router.navigateByUrl("/individual-more-than-onecrore");
                                }
                            } else {
                                if (
                                    this.userDetails.subCategory.applicantSubCategory == 5 &&
                                    (this.applicationDetailsArray1.statusNo != 2 ||
                                        this.applicationDetailsArray1.statusNo != 6 ||
                                        this.applicationDetailsArray1.statusNo != 7 ||
                                        this.applicationDetailsArray1.statusNo != 22)
                                ) {
                                    this.enableAlert = true;
                                }
                            }
                        }
                    } else {
                        if (this.userDetails.subCategory.applicantSubCategory == 5) {
                            this.href = this.router.url;
                            if (this.href == "/individual-more-than-onecrore") {
                                window.location.reload();
                                this.router.navigateByUrl("/individual-more-than-onecrore");
                            } else {
                                this.router.navigateByUrl("/individual-more-than-onecrore");
                            }
                        }
                    }
                } else {
                    if (this.userDetails.subCategory.applicantSubCategory == 5) {
                        this.href = this.router.url;
                        if (this.href == "/individual-more-than-onecrore") {
                            window.location.reload();
                            this.router.navigateByUrl("/individual-more-than-onecrore");
                        } else {
                            this.router.navigateByUrl("/individual-more-than-onecrore");
                        }
                    }
                }
            } else {
                if (this.userDetails.subCategory.applicantSubCategory == 5) {
                    this.router.navigateByUrl("/individual-more-than-onecrore");
                }
            }
        });
    }

    restrictRoutingLess() {
        this.sharedService.getLocalStorageItem("userDetails").then((resp: any) => {
            this.userDetails = resp;
            if (this.userDetails.sponsorAgencyFlag != null) {
                if (
                    this.userDetails.sponsorAgencyFlag.trim() == "Y" &&
                    this.userDetails.sponsorAgencyName.trim() == "1"
                ) {
                    this.dashboardService.getApplicationDetailsNRLMInd().then((resp: any) => {
                        
                        if (resp.body.responseCode == 404) {
                            this.shgNRLMMore.show();
                            this.getNrlmListData();
                        } else if (resp.body.responseCode == 200) {
                            const resObj = JSON.parse(this.sharedService.aesDecryption(resp["body"]["responseObject"]));
                            if (resObj.applications[0].statusNo == 22) {
                                this.shgNRLMMore.show();
                                this.getNrlmListData();
                            } else {
                                this.getApplicationStatus3();
                            }
                        } else {
                            this.getApplicationStatus3();
                        }
                    });
                    // if (this.sharedService.getLocalStorageItem('getApplicantIDDetails') == null || this.sharedService.getLocalStorageItem('getApplicantIDDetails') == undefined ) {

                    // }else{
                    //     this.getShgApplicantId();

                    // }
                } else {
                    this.getApplicationStatus3();
                    // this.showTabNRLM = false;
                }
            } else {
                this.getApplicationStatus3();
            }

            // console.log(" this.showTabNRLM ", this.showTabNRLM );
        });
        // this.getApplicationStatus3();
    }

    // getApplicationStatus3() {
    //   this.sharedService.getLocalStorageItem('applicationDetailsArray').then((resp: any) => {
    //     console.log('RESPPPP : ', resp)
    //     if (resp) {
    //       this.applicationDetailsArray1 = resp;
    //       console.log('this.applicationDetailsArrayyyyy : ', this.applicationDetailsArray2)
    //       if (this.applicationDetailsArray1) {
    //         if (this.userDetails.subCategory.applicantSubCategory == 6 && (this.applicationDetailsArray1.statusNo == 2 || this.applicationDetailsArray1.statusNo == 6)) {
    //           this.router.navigateByUrl('/individual-less-than-onecrore');

    //         } else {
    //           if (this.userDetails.subCategory.applicantSubCategory == 6 && (this.applicationDetailsArray1.statusNo != 2 || this.applicationDetailsArray1.statusNo != 6)) {
    //             this.enableAlert = true;

    //           }
    //         }
    //       }

    //     } else {
    //       if (this.userDetails.subCategory.applicantSubCategory == 6) {
    //         this.router.navigateByUrl('/individual-less-than-onecrore');
    //       }

    //     }
    //   })
    // }

    getApplicationStatus3() {
        console.log("going here");
        this.dashboardService.getApplicationDetails().then((resp: any) => {
            if (resp.status == 200) {
                if (resp["body"]["responseCode"] == 200) {
                    if (resp["body"].responseObject) {
                        // this.applicationDetailsArray1 = resp['body']['responseObject'].applications[0];

                        const resObj = JSON.parse(this.sharedService.aesDecryption(resp["body"]["responseObject"]));

                        this.applicationDetailsArray1 = resObj.applications[0];

                        console.log(this.applicationDetailsArray1.statusNo);

                        if (this.applicationDetailsArray1) {
                            // this.sharedService.setLocalStorage('applicationDetailsArray', JSON.stringify(this.applicationDetailsArray1));
                            if (
                                this.applicationDetailsArray1.statusNo == 22 ||
                                this.applicationDetailsArray1.statusNo == 11 ||
                                this.applicationDetailsArray1.statusNo == 7
                            ) {
                                this.sharedService.clearLocalStorageItem("applicationDetailsArray");
                            } else {
                                this.sharedService.setLocalStorage(
                                    "applicationDetailsArray",
                                    JSON.stringify(this.applicationDetailsArray1)
                                );
                            }
                            if (
                                this.userDetails.subCategory.applicantSubCategory == 6 &&
                                (this.applicationDetailsArray1.statusNo == 2 ||
                                    this.applicationDetailsArray1.statusNo == 6 ||
                                    this.applicationDetailsArray1.statusNo == 7 ||
                                    this.applicationDetailsArray1.statusNo == 11 ||
                                    this.applicationDetailsArray1.statusNo == 22)
                            ) {
                                this.href = this.router.url;
                                if (this.href == "/individual-less-than-onecrore") {
                                    window.location.reload();
                                    this.router.navigateByUrl("/individual-less-than-onecrore");
                                } else {
                                    this.router.navigateByUrl("/individual-less-than-onecrore");
                                }
                            } else {
                                if (
                                    this.userDetails.subCategory.applicantSubCategory == 6 &&
                                    (this.applicationDetailsArray1.statusNo != 2 ||
                                        this.applicationDetailsArray1.statusNo != 6 ||
                                        this.applicationDetailsArray1.statusNo != 7 ||
                                        this.applicationDetailsArray1.statusNo != 22)
                                ) {
                                    this.enableAlert = true;
                                }
                            }
                        }
                    } else {
                        if (this.userDetails.subCategory.applicantSubCategory == 6) {
                            this.href = this.router.url;
                            if (this.href == "/individual-less-than-onecrore") {
                                window.location.reload();
                                this.router.navigateByUrl("/individual-less-than-onecrore");
                            } else {
                                this.router.navigateByUrl("/individual-less-than-onecrore");
                            }
                        }
                    }
                } else {
                    if (this.userDetails.subCategory.applicantSubCategory == 6) {
                        this.href = this.router.url;
                        if (this.href == "/individual-less-than-onecrore") {
                            window.location.reload();
                            this.router.navigateByUrl("/individual-less-than-onecrore");
                        } else {
                            this.router.navigateByUrl("/individual-less-than-onecrore");
                        }
                    }
                }
            } else {
                if (this.userDetails.subCategory.applicantSubCategory == 6) {
                    this.router.navigateByUrl("/individual-less-than-onecrore");
                }
            }
        });
    }

    clickOk() {
        this.router.navigateByUrl("/Home");
        this.enableAlert = false;
    }

    restrictRoutingShg() {
        //  this.getSHGApplicationStatus();

        this.sharedService.getLocalStorageItem("userDetails").then((resp: any) => {
            this.userDetails = resp;
            if (this.userDetails.sponsorAgencyFlag != null) {
                if (
                    this.userDetails.sponsorAgencyFlag.trim() == "Y" &&
                    this.userDetails.sponsorAgencyName.trim() == "1"
                ) {
                    this.dashboardService.getApplicantDetailsNRLM().then((resp: any) => {
                        console.log("hello", resp);
                        console.log("hello", resp.body);
                        if (resp.body.responseCode == 404) {
                            this.shgNRLM.show();
                            this.getNrlmListData();
                        } else if (resp.body.responseCode == 200) {
                            console.log(resp.body["responseObject"]);
                            if (resp.body["responseObject"]["userApplications"][0]["statuCode"] == 22) {
                                this.shgNRLM.show();
                                this.getNrlmListData();
                            } else {
                                this.getShgApplicantId();
                            }
                        } else {
                            this.getShgApplicantId();
                        }
                    });
                    // if (this.sharedService.getLocalStorageItem('getApplicantIDDetails') == null || this.sharedService.getLocalStorageItem('getApplicantIDDetails') == undefined ) {

                    // }else{
                    //     this.getShgApplicantId();

                    // }
                } else {
                    this.getShgApplicantId();
                    // this.showTabNRLM = false;
                }
            }else{
                this.getShgApplicantId();
            }

            // console.log(" this.showTabNRLM ", this.showTabNRLM );
        });
        //this.getShgApplicantId();
    }

    getShgApplicantId() {
        this.dashboardService.getShgApplicantId().then(
            (resp: any) => {
                if (resp.status == 200) {
                    if (resp["body"]["responseCode"] == 200) {
                        // this.getApplicantIDDetails = resp['body']['responseObject'].userApplications[0];
                        this.sHGApplicationStatus = resp["body"]["responseObject"].userApplications[0];

                        if (this.sHGApplicationStatus) {
                            if (
                                this.userDetails.subCategory.applicantSubCategory == 1 &&
                                (this.sHGApplicationStatus.statuCode == 2 ||
                                    this.sHGApplicationStatus.statuCode == 6 ||
                                    this.sHGApplicationStatus.statuCode == 7 ||
                                    this.sHGApplicationStatus.statuCode == 22 ||
                                    this.sHGApplicationStatus.statuCode == 11)
                            ) {
                                this.href = this.router.url;
                                if (this.href == "/Data-Input-Sheet-SHG") {
                                    window.location.reload();
                                    this.router.navigateByUrl("/Data-Input-Sheet-SHG");
                                } else {
                                    this.router.navigateByUrl("/Data-Input-Sheet-SHG");
                                }
                            } else {
                                if (
                                    this.sHGApplicationStatus.statuCode != 2 &&
                                    this.sHGApplicationStatus.statuCode != 6 &&
                                    this.sHGApplicationStatus.statuCode != 7 &&
                                    this.sHGApplicationStatus.statuCode != 11 &&
                                    this.sHGApplicationStatus.statuCode != 22 &&
                                    this.userDetails.subCategory.applicantSubCategory == 1
                                ) {
                                    this.enableAlert = true;
                                }
                            }
                        } else {
                            if (this.userDetails.subCategory.applicantSubCategory == 1) {
                                this.href = this.router.url;
                                if (this.href == "/Data-Input-Sheet-SHG") {
                                    window.location.reload();
                                    this.router.navigateByUrl("/Data-Input-Sheet-SHG");
                                } else {
                                    this.router.navigateByUrl("/Data-Input-Sheet-SHG");
                                }
                            }
                        }

                        if (
                            this.sHGApplicationStatus.statuCode == 22 ||
                            this.sHGApplicationStatus.statuCode == 11 ||
                            this.sHGApplicationStatus.statuCode == 7
                        ) {
                            this.sharedService.clearLocalStorageItem("getApplicantIDDetails");
                        } else {
                            this.sharedService.setLocalStorage(
                                "getApplicantIDDetails",
                                JSON.stringify(this.sHGApplicationStatus)
                            );
                            console.log("here");
                        }
                    } else {
                        if (this.userDetails.subCategory.applicantSubCategory == 1) {
                            this.href = this.router.url;
                            if (this.href == "/Data-Input-Sheet-SHG") {
                                window.location.reload();
                                this.router.navigateByUrl("/Data-Input-Sheet-SHG");
                            } else {
                                this.router.navigateByUrl("/Data-Input-Sheet-SHG");
                            }
                        }
                    }
                }
            },
            (error) => {
                // this.sharedService.showWarning(COMMONTEXT.API_ERROR)
            }
        );
    }

    restrictRoutingCoop() {
       this.getCoopApplicantId();
    }
    

    getCoopApplicantId() {
        this.dashboardService.getShgApplicantId().then(
            (resp: any) => {
                if (resp.status == 200) {
                    if (resp["body"]["responseCode"] == 200) {
                        // this.getApplicantIDDetails = resp['body']['responseObject'].userApplications[0];
                        this.coopApplicationStatus = resp["body"]["responseObject"].userApplications[0];
                        //console.log('this.coopApplicationStatus', this.coopApplicationStatus)
                        // this.sharedService.setLocalStorage('getApplicantIDDetails', JSON.stringify(this.coopApplicationStatus));
                        if (
                            this.coopApplicationStatus.statuCode == 22 ||
                            this.coopApplicationStatus.statuCode == 11 ||
                            this.coopApplicationStatus.statuCode == 7
                        ) {
                            this.sharedService.clearLocalStorageItem("getApplicantIDDetails");
                        } else {
                            this.sharedService.setLocalStorage(
                                "getApplicantIDDetails",
                                JSON.stringify(this.coopApplicationStatus)
                            );

                            console.log("here");
                        }
                        if (this.coopApplicationStatus) {
                            if (
                                this.userDetails.subCategory.applicantSubCategory == 3 &&
                                (this.coopApplicationStatus.statuCode == 2 ||
                                    this.coopApplicationStatus.statuCode == 6 ||
                                    this.coopApplicationStatus.statuCode == 7 ||
                                    this.coopApplicationStatus.statuCode == 22 ||
                                    this.coopApplicationStatus.statuCode == 11)
                            ) {
                                this.router.navigateByUrl("/Data-Input-Sheet-Cooperative");
                            } else {
                                if (
                                    this.coopApplicationStatus.statuCode != 2 &&
                                    this.coopApplicationStatus.statuCode != 6 &&
                                    this.coopApplicationStatus.statuCode != 7 &&
                                    this.coopApplicationStatus.statuCode != 22 &&
                                    this.userDetails.subCategory.applicantSubCategory == 3
                                ) {
                                    this.enableAlert = true;
                                }
                            }
                        } else {
                            if (this.userDetails.subCategory.applicantSubCategory == 3) {
                                this.router.navigateByUrl("/Data-Input-Sheet-Cooperative");
                            }
                        }
                    } else {
                        if (this.userDetails.subCategory.applicantSubCategory == 3) {
                            this.router.navigateByUrl("/Data-Input-Sheet-Cooperative");
                        }
                    }
                }
            },
            (error) => {
                // this.sharedService.showWarning(COMMONTEXT.API_ERROR)
            }
        );
    }

    // getSHGApplicationStatus() {
    //   this.sharedService.getLocalStorageItem('getApplicantIDDetails').then((resp: any) => {
    //     console.log('RESPPPP : ', resp)
    //     if (resp) {
    //       this.sHGApplicationStatus = resp;
    //       console.log('this.sHGApplicationStatus : ', this.sHGApplicationStatus)
    //       if (this.sHGApplicationStatus ) {
    //         if (this.userDetails.subCategory.applicantSubCategory == 1 && this.sHGApplicationStatus.statuCode == 2) {
    //           this.router.navigateByUrl('/Data-Input-Sheet-SHG');
    //         }
    //          else {

    //           if (this.sHGApplicationStatus.statuCode != 2 && this.userDetails.subCategory.applicantSubCategory == 1) {
    //             this.enableAlert = true;
    //            }
    //         }
    //       }
    //     } else {
    //       if (this.userDetails.subCategory.applicantSubCategory == 1) {
    //         this.router.navigateByUrl('/Data-Input-Sheet-SHG');
    //       }
    //     }
    //   })
    // }

    restrictRoutingFpc() {
        this.getFpcApplicationStatus();
    }

    getFpcApplicationStatus() {
        this.dashboardService.getShgApplicantId().then(
            (resp: any) => {
                if (resp.status == 200) {
                    if (resp["body"]["responseCode"] == 200) {
                        // this.getApplicantIDDetails = resp['body']['responseObject'].userApplications[0];
                        this.fpcApplicationStatus = resp["body"]["responseObject"].userApplications[0];
                        // this.sharedService.setLocalStorage('getApplicantIDDetails', JSON.stringify(this.fpcApplicationStatus));
                        if (
                            this.fpcApplicationStatus.statuCode == 22 ||
                            this.fpcApplicationStatus.statuCode == 11 ||
                            this.fpcApplicationStatus.statuCode == 7
                        ) {
                            this.sharedService.clearLocalStorageItem("getApplicantIDDetails");
                        } else {
                            this.sharedService.setLocalStorage(
                                "getApplicantIDDetails",
                                JSON.stringify(this.fpcApplicationStatus)
                            );
                            console.log("here");
                        }
                        if (this.fpcApplicationStatus) {
                            if (
                                this.fpcApplicationStatus.statuCode != 2 &&
                                this.fpcApplicationStatus.statuCode != 6 &&
                                this.fpcApplicationStatus.statuCode != 7 &&
                                this.fpcApplicationStatus.statuCode != 11 &&
                                this.fpcApplicationStatus.statuCode != 22 &&
                                this.userDetails.subCategory.applicantSubCategory == 2
                            ) {
                                this.enableAlert = true;
                            } else {
                                if (
                                    this.userDetails.subCategory.applicantSubCategory == 2 &&
                                    (this.fpcApplicationStatus.statuCode == 2 ||
                                        this.fpcApplicationStatus.statuCode == 6 ||
                                        this.fpcApplicationStatus.statuCode == 7 ||
                                        this.fpcApplicationStatus.statuCode == 22 ||
                                        this.fpcApplicationStatus.statuCode == 11)
                                ) {
                                    this.router.navigateByUrl("/Data-Input-Sheet-FPC");
                                }
                            }
                        } else {
                            if (this.userDetails.subCategory.applicantSubCategory == 2) {
                                this.router.navigateByUrl("/Data-Input-Sheet-FPC");
                            }
                        }
                    } else {
                        if (this.userDetails.subCategory.applicantSubCategory == 2) {
                            this.router.navigateByUrl("/Data-Input-Sheet-FPC");
                        }
                    }
                }
            },
            (error) => {
                // this.sharedService.showWarning(COMMONTEXT.API_ERROR)
            }
        );
    }

    // getFpcApplicationStatus() {
    //   this.sharedService.getLocalStorageItem('getApplicantIDDetails').then((resp: any) => {
    //     console.log('RESPPPP : ', resp)
    //     if (resp) {
    //       this.fpcApplicationStatus = resp;
    //       console.log('this.fpcApplicationStatus : ', this.fpcApplicationStatus)
    //       if (this.fpcApplicationStatus) {
    //         if (this.fpcApplicationStatus.statuCode != 2 && this.userDetails.subCategory.applicantSubCategory == 2) {
    //           this.enableAlert = true;
    //          } else {
    //           if (this.userDetails.subCategory.applicantSubCategory == 2 && this.fpcApplicationStatus.statuCode == 2) {
    //             this.router.navigateByUrl('/Data-Input-Sheet-FPC');
    //           }
    //         }
    //       }
    //     } else {
    //       if (this.userDetails.subCategory.applicantSubCategory == 2) {
    //         this.router.navigateByUrl('/Data-Input-Sheet-FPC');
    //       }
    //     }
    //   })
    // }

    restrictRoutingComInf() {

    
        this.sharedService.getLocalStorageItem("userDetails").then((resp: any) => {
            this.userDetails = resp;
            
            if (this.userDetails.sponsorAgencyFlag != null) {
                if (
                    this.userDetails.sponsorAgencyFlag.trim() == "Y" &&
                    this.userDetails.sponsorAgencyName.trim() == "1" && this.userDetails.subCategory.applicantSubCategory == 8
                ) {
                    this.dashboardService.getApplicantDetailsNRLM().then((resp: any) => {
                        console.log("hello", resp);
                        
                        console.log("hello", resp.body);
                        if (resp.body.responseCode == 404) {
                            this.shgNRLMCommonInfro.show();
                            this.getNrlmListData();
                        } else if (resp.body.responseCode == 200) {
                            console.log(resp.body["responseObject"]);
                            if (resp.body["responseObject"]["userApplications"][0]["statuCode"] == 22) {
                                this.shgNRLMCommonInfro.show();
                                this.getNrlmListData();
                            } else {
                                this.getCommonInfraApplicantId();
                            }
                        } else {
                            this.getCommonInfraApplicantId();
                        }
                    });
                    // if (this.sharedService.getLocalStorageItem('getApplicantIDDetails') == null || this.sharedService.getLocalStorageItem('getApplicantIDDetails') == undefined ) {

                    // }else{
                    //     this.getShgApplicantId();

                    // }
                } else {
                    this.getCommonInfraApplicantId();
                    // this.showTabNRLM = false;
                }
            }else{
                this.getCommonInfraApplicantId();
            }

            // console.log(" this.showTabNRLM ", this.showTabNRLM );
        });
        // this.getCommonInfraApplicantId();
    }

    getCommonInfraApplicantId() {
        this.dashboardService.getCommonInfraApplicantId().then(
            (resp: any) => {
                
                if (resp.status == 200) {
                    if (resp["body"]["responseCode"] == 200) {
                       
                        // this.getApplicantIDDetails = resp['body']['responseObject'].userApplications[0];
                        this.comInfApplicationStatus = resp["body"]["responseObject"].userApplications[0];
                        //console.log('this.comInfApplicationStatus', this.comInfApplicationStatus)
                        // this.sharedService.setLocalStorage('getApplicantIDDetails', JSON.stringify(this.comInfApplicationStatus));

                        if (
                            this.comInfApplicationStatus.statuCode == 22 ||
                            this.comInfApplicationStatus.statuCode == 11 ||
                            this.comInfApplicationStatus.statuCode == 7
                        ) {
                            this.sharedService.clearLocalStorageItem("commonInfraApplicantId");
                        } else {
                            this.sharedService.setLocalStorage(
                                "commonInfraApplicantId",
                                JSON.stringify(this.comInfApplicationStatus)
                            );
                            console.log("here");
                        }
                        if (this.comInfApplicationStatus) {
                            if (
                                this.comInfApplicationStatus.statuCode == 2 ||
                                this.comInfApplicationStatus.statuCode == 6 ||
                                this.comInfApplicationStatus.statuCode == 7 ||
                                this.comInfApplicationStatus.statuCode == 22 ||
                                this.comInfApplicationStatus.statuCode == 11
                            ) {
                                this.href = this.router.url;
                                if (this.href == "/Common-Infrastructure") {
                                    window.location.reload();
                                    this.router.navigateByUrl("/Common-Infrastructure");
                                }else{
                                    this.router.navigateByUrl("/Common-Infrastructure");
                                }
                                
                            } else {
                                if (
                                    this.comInfApplicationStatus.statuCode != 2 &&
                                    this.comInfApplicationStatus.statuCode != 6 &&
                                    this.comInfApplicationStatus.statuCode != 7 &&
                                    this.comInfApplicationStatus.statuCode != 22
                                ) {
                                    this.enableAlert = true;
                                }
                            }
                        } else {
                            this.router.navigateByUrl("/Common-Infrastructure");
                        }
                    } else {
                        this.href = this.router.url;
                        if (this.href == "/Common-Infrastructure") {
                            window.location.reload();
                            this.router.navigateByUrl("/Common-Infrastructure");
                        }else{
                            this.router.navigateByUrl("/Common-Infrastructure");
                        }
                    }
                }
            },
            (error) => {
                // this.sharedService.showWarning(COMMONTEXT.API_ERROR)
            }
        );
    }

    widthdraw() {
        //console.log('User Details:', this.userDetails);
        if (this.userDetails.category.applicantCategory == 1) {
            this.sharedService.getLocalStorageItem("applicationDetailsArray").then((resp: any) => {
                //console.log('1 : ', resp)
                this.appDetailsWid = resp;
                this.widthdrawModal.show();
            });
        }

        if (this.userDetails.category.applicantCategory == 2) {
            this.sharedService.getLocalStorageItem("getApplicantIDDetails").then((resp: any) => {
                //console.log('2 : ', resp)
                this.appDetailsWid = resp;
                this.widthdrawModal.show();
            });
        }

        if (this.userDetails.category.applicantCategory == 5) {
            this.sharedService.getLocalStorageItem("commonInfraApplicantId").then((resp: any) => {
                //console.log('5 : ', resp)
                this.appDetailsWid = resp;
                this.widthdrawModal.show();
            });
        }
    }

    closeWidthdrawModal() {
        this.widthdrawModal.hide();
        this.formSubmittedWid = false;
        this.widthdrawForm.reset();
    }
    closeshgNRLM() {
        this.shgNRLM.hide();
    }

    closeshgNRLMMore() {
        this.shgNRLMMore.hide();
    }


    closeshgNRLMCommonInfra() {
        this.shgNRLMCommonInfro.hide();
    }

    submitshgNRLM() {
        this.shgNRLM.hide();
    }
    // submitshgNRLMMore(){
    //     this.shgNRLMMore.hide();
    // }

    submitWidthdrawl() {
        this.formSubmittedWid = true;

        if (this.widthdrawForm.valid) {
            const obj = {
                applicationId: this.appDetailsWid.applicationId,
                reason: this.widthdrawForm.controls["widthdrawReason"].value,
                status: 22,
            };

            this.dashboardService.widrawApplication(obj).then(
                (resp: any) => {
                    if (resp.status == 200) {
                        if ((resp["body"]["responseCode"] = 200)) {
                            this.sharedService.showSuccess(resp["body"]["responseDesc"]);
                            if (this.userDetails.category.applicantCategory == 1) {
                                this.sharedService.clearLocalStorageItem("applicationDetailsArray");
                                //console.log('Cleared for Ind')
                            }

                            if (this.userDetails.category.applicantCategory == 2) {
                                this.sharedService.clearLocalStorageItem("getApplicantIDDetails");
                                //console.log('Cleared for Non Ind')
                            }

                            if (this.userDetails.category.applicantCategory == 5) {
                                this.sharedService.clearLocalStorageItem("commonInfraApplicantId");
                                //console.log('Cleared for Comm Infra')
                            }
                            this.enableAlert = false;
                            this.closeWidthdrawModal();
                        } else {
                            this.sharedService.showError(resp["body"]["responseDesc"]);
                            this.formSubmittedWid = false;
                            this.widthdrawForm.reset();
                        }
                    }
                },
                (error) => {
                    this.sharedService.showWarning(COMMONTEXT.API_ERROR);
                }
            );
        } else {
            this.sharedService.showWarning("Please enter reason");
        }
    }

    getApplicantsCategory() {
        this.commonService.getApplicantsCategoryTraining().then((resp) => {
            if (resp["status"] == 200 && resp["body"]["responseCode"] == 200) {
                this.applicantsType = resp["body"]["responseObject"]["applicantCategories"];
                // console.log(this.applicantsType);
            }
        });
    }

    category(event) {
        this.nonIndividualValue = event;
        if (event == 2) {
            this.typeNonInd = true;
            this.enableCommonInfra = false;
            this.getAppSubCategory(event);
        } else if (event == 5) {
            this.enableCommonInfra = true;
            this.typeNonInd = false;
            this.getAppSubCategory(event);
        } else {
            this.typeNonInd = false;
            this.enableCommonInfra = false;
            // this.subCategoryList = [];
        }
    }

    getAppSubCategory(applicantCategoryId) {
        this.commonService.getAppSubCategory(applicantCategoryId).then((resp: any) => {
            if (resp.status == 200) {
                if (resp["body"]) {
                    if (resp["body"].responseObject) {
                        this.subCategoryList = resp["body"]["responseObject"].applicantSubCategories;
                        // console.log("SubCategory List: ", this.subCategoryList);
                    }
                }
            }
        });
    }

    restrictRoutingTraining() {
        this.forTrainingUser.show();
        this.getApplicantsCategory();
    }

    closeTrainingModal() {
        this.forTrainingUser.hide();
    }

    onSelect(event) {
        // console.log('EVENT : ' + JSON.stringify(event))

        this.subCategoryValue = event;
    }

    updateTrainingStatus() {
        //  this.statusModal.show();

        // this._compiler.clearCache();
        // this.sharedService.clearAllLocalStorage();
        this.formSubmitted = true;
        if (this.applicantCategory.valid) {
            if (this.nonIndividualValue == 2 || this.nonIndividualValue == 5) {
                if (this.subCategoryValue == undefined) {
                    this.sharedService.showWarning("Please Select type of Non Individual");
                } else {
                    // this.registerUser();
                    this.statusModal.show();
                }
            } else {
                this.statusModal.show();
            }
        }
    }

    closeConfirmation() {
        this.statusModal.hide();
    }

    updateTrainingStatus1() {
        const authObject = {
            // aadhaar: this.userDetails.aadhar,
            //category: this.userDetails.category.applicantCategory,
            category: this.nonIndividualValue,
            // districtCode: this.userDetails.district.districtCode,
            // email: this.userDetails.email,
            // mobile: null,
            // name: this.userDetails.name,
            //stateCode: this.userDetails.state.stateCode,
            // subCategory: this.selectedSubCategory
        };

        if (this.nonIndividualValue == 2 || this.nonIndividualValue == 5) {
            authObject["subCategory"] = this.subCategoryValue.applicantSubCategory;
        } else {
            authObject["subCategory"] = null;
        }

        this.dashboardService.updateUser(authObject).then(
            (resp: any) => {
                if (resp.status == 200) {
                    if (resp["body"]["responseCode"] == 200) {
                        this.sharedService.showSuccess(resp["body"]["responseDesc"]);
                        this.dierectlogOut();
                    } else {
                        this.sharedService.showError(resp["body"]["responseDesc"]);
                        // this.enableFormDetails = true;
                    }
                } else {
                    this.sharedService.showError(resp["body"]["responseDesc"]);
                    // this.enableFormDetails = true;
                }
            },
            (error) => {
                // this.sharedService.showWarning(COMMONTEXT.API_ERROR)
            }
        );
    }

    dierectlogOut() {
        this.userManagementService.logout().then((resp: any) => {
            if (resp.status == 200) {
                if (resp["body"]["responseCode"] == 200) {
                    this.router.navigateByUrl("/Login");
                }
            }
        });
    }

    logOut() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                reload: true,
            },
        };
        this.router.navigateByUrl("/Login", navigationExtras);
        // this.router.navigate(['/Login'])
        // location.reload();
    }

    getAdditionalDocuments() {
        const body = {
            action: "action",
        };
        this.commonService.getAdditionalDocuments(729902, body).then((resp) => {
            if (resp["status"] == 200 && resp["body"]["responseCode"] == 200) {
                console.log("this is the response", resp);
            }
        });
    }

    // Make additional document section visible if application details status is 37
    getApplicationDetails() {
        console.log("here");

        this.dashboardService.getShgApplicantId().then((resp: any) => {
            console.log("this is response", resp);
            if (resp.status == 200) {
                if (resp["body"]) {
                    if (resp["body"].responseObject) {
                        if (resp["body"].responseObject.userApplications.length > 0) {
                            console.log("here 2");
                            const body = {
                                action: "UPLOAD",
                            };

                            const bodyDownload = {
                                action: "DOWNLOAD",
                            };
                            this.commonService
                            .getAdditionalDocuments(resp["body"].responseObject.userApplications[0].applicationId, body)
                            .then((additionalDocResp: any) => {
                                console.log("this is response", resp);
                                if (additionalDocResp.status == 200) {
                                    if (additionalDocResp["body"]) {
                                        if (additionalDocResp["body"].responseObject) {
                                            console.log(
                                                "response object",
                                                additionalDocResp["body"].responseObject.additionalDocumentsResponse
                                            );
                                            if (
                                                additionalDocResp["body"].responseObject.additionalDocumentsResponse
                                                    .length > 0
                                            ) {
                                                this.additionDocumentDetail = true;
                                            } else {
                                                this.commonService
                                                .getAdditionalDocuments(
                                                    resp["body"].responseObject.userApplications[0].applicationId,
                                                    bodyDownload
                                                )
                                                .then((additionalDocRespDownload: any) => {
                                                    console.log("this is response", resp);
                                                    if (additionalDocRespDownload.status == 200) {
                                                        if (additionalDocRespDownload["body"]) {
                                                            if (additionalDocRespDownload["body"].responseObject) {
                                                                console.log(
                                                                    "response object",
                                                                    additionalDocRespDownload["body"].responseObject
                                                                );
                                                                if (
                                                                    additionalDocRespDownload["body"].responseObject
                                                                        .length > 0
                                                                ) {
                                                                    this.additionDocumentDetail = true;
                                                                } else {
                                                                    this.additionDocumentDetail = false;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                            });
                        }
                        console.log("this is additional document detail", this.additionDocumentDetail);
                    }
                }
            } else {
                console.log("helloooooooooooo");
            }
        });
    }

    getSanctionedApplications() {
        this.applicationService.getUpdatedLatLong().then((resp: any) => {
            if (resp.status == 200) {
                if (resp["body"]["responseCode"] == 200) {
                    if (resp.body.responseObject) {
                        console.log("************************", resp["body"].responseObject);
                        this.sanctionApplications = true;
                    } else {
                        this.sanctionApplications = false;
                    }
                }
            }
        });
    }
    addOtherMembers(member, data) {
        console.log("member", member);
        this.addedMmber.push(member);
        this.getShgApplicantId();
        this.shgNRLM.hide();
        console.log("this is addedMember", this.addedMmber);
        this.sharedService.setLocalStorage("getShgID", JSON.stringify(data));
        this.addedMemberCode = member;
        this.getMemberDetail(member);
        console.log(this.addedMemberCode);
    }

    addOtherMembersIndividuals(member, data) {
        console.log("addOtherMembersIndividuals", member);
        this.addedMmber.push(member);
        this.getApplicationStatus();
        this.shgNRLM.hide();
        console.log("this is addedMember", this.addedMmber);
        this.sharedService.setLocalStorage("getShgID", JSON.stringify(data));
        this.addedMemberCode = member;
        // this.getMemberDetail(member);
        console.log(this.addedMemberCode);
    }

    addOtherMembersIndividualsMore(member, data) {
        console.log("addOtherMembersIndividuals", member);
        this.addedMmber.push(member);
        this.getApplicationStatus2();
        this.shgNRLMMore.hide();
        console.log("this is addedMember", this.addedMmber);
        this.sharedService.setLocalStorage("getShgID", JSON.stringify(data));
        this.addedMemberCode = member;
        // this.getMemberDetail(member);
        console.log(this.addedMemberCode);
    }

    addOtherMembersIndividualsLess(member, data) {
        console.log("addOtherMembersIndividuals", member);
        this.addedMmber.push(member);
        this.getApplicationStatus3();
        this.shgNRLMMore.hide();
        console.log("this is addedMember", this.addedMmber);
        this.sharedService.setLocalStorage("getShgID", JSON.stringify(data));
        this.addedMemberCode = member;
        // this.getMemberDetail(member);
        console.log(this.addedMemberCode);
    }

    
    addOtherMembersCommonInfra(member, data) {
        console.log("addOtherMembersIndividuals", member);
        this.addedMmber.push(member);
        this.getCommonInfraApplicantId();
        this.shgNRLMCommonInfro.hide();
        console.log("this is addedMember", this.addedMmber);
        this.sharedService.setLocalStorage("getShgID", JSON.stringify(data));
        this.addedMemberCode = member;
        // this.getMemberDetail(member);
        console.log(this.addedMemberCode);
    }

    nrlmDate(date) {
        return date.split("T")[0];
    }

}

