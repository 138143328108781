import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart, Event, NavigationEnd, NavigationExtras } from '@angular/router';
import { SharedService } from 'src/app/services/shared/shared.service';
import { NgxSpinnerService } from "ngx-spinner";
import { APP_RELOAD } from './app.constants';
import { iif } from 'rxjs';
import { environment } from '../environments/environment';

declare const gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {
  // @Output() updateSideBar : EventEmitter<any> = new EventEmitter<any>();
  title = 'E-TMR';
  showHead = false;//MAINPAGE_PARAMETERS.showHead;
  showFooter = false;
  private loggedIn = false;
  fontSize = 14;
  // @ViewChild('para') para: ElementRef;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef
  ) {
    this.addGAScript();
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // sharedService.showLoader();
        this.showFooter = false;
      }
      event['url']
      
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        sharedService.hideLoader();
        console.log("event['url'] " , event['url'])
        console.log('Sub String', event['url'].includes('Generate-Password'));
        let generatePassword = event['url'].includes('Generate-Password')
        if (event['url'] == '/Home-Page' || event['url'] == '/public-dashboard' || event['urlAfterRedirects'] == '/Home-Page') {
          this.showHead = false;
          this.showFooter = false;
        } else if (event['url'] == '/Login' || event['url'] == undefined || event['url'] == null) {
          this.showHead = false;
        } else if (event['url'] == '/sub' ) {
          console.log('Here');
          
          this.showHead = false;
          this.showFooter = false;
        } 
        else if (event['url'] == '/Register-New-User' || event['url'] == '/Register-New-User2' || event['url'] == '/Validate-Token' || generatePassword) {
          console.log('HI')
          this.showHead = false;
          this.showFooter = false;
        } 
        else if (event['url'] == '/HomePre') {
          this.showHead = false;
          this.showFooter = false;
        } else if(event['url'].indexOf('Validate-Token') !== -1 ||  event['url'].indexOf('V-Token') !== -1) {
          console.log('Validate-Token ' )
          this.showHead = false;
          this.showFooter = false;
        }  else {
          this.showHead = true;
          this.showFooter = true;
          console.log('403 *****',  event['url'].indexOf('V-Token'), event['url'].indexOf('Validate-Token'))
        }
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
      })
        //this.showFooter = true;
      }
    })

    var bd = document.getElementsByTagName('body')[0];
    var time = new Date().getTime();

    bd.onmousemove = goLoad;
    function goLoad() {
      if (new Date().getTime() - time >= APP_RELOAD.TIMER) {
        time = new Date().getTime();
        // console.log('1')
        // router.navigateByUrl('/Home');
        // window.location.reload(true);
        const navigationExtras: NavigationExtras = {

          queryParams: {

            reload: true

          }

        };

        router.navigateByUrl('/Login', navigationExtras)
      } else {
        // console.log('2')
        time = new Date().getTime();
      }
    }

    // router.events.subscribe( (event: Event) => {
    //   if (event instanceof NavigationStart) {
    //       // Show loading indicator
    //   }

    //   if (event instanceof NavigationEnd) {
    //       // Hide loading indicator
    //   }

    // });
    sessionStorage.setItem('loggedIn', 'false');

  }

  ngOnInit() {
    //setTimeout(this.tryy,5000);

  }

  ngAfterViewChecked() {
    // this.commonService.showHead.subscribe(value => {
    //   this.showHead = (value == true);
    //   this.cdRef.detectChanges();
    //   this.showFooter=true;
    // });
  }


  changeFont(operator) {
    // operator === '+' ? this.fontSize++ : this.fontSize--;
    // (this.para.nativeElement as HTMLParagraphElement).style.fontSize = `${this.fontSize}px`;
    // if( operator == '+') {
    //   document.getElementById('para').style.fontSize = '150%';
    // } else if( operator == '-') {
    //   document.getElementById('para').style.fontSize = '80%';
    // }
    
    // operator === '+' ? this.fontSize++ : this.fontSize--; document.getElementsByTagName('div')[0].style.fontSize = `javascript">${this.fontSize}px`;

    
  }

  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
    document.head.appendChild(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
}




}
