import {Response, RequestOptions, Http,} from '@angular/http';
import {map, tap} from 'rxjs/operators';
import {Observable} from "rxjs";
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse, HttpRequest} from '@angular/common/http';
import {environment} from 'src/environments/environment'

@Injectable()
export class BaseServiceService {
    apiURL: string = 'http://api.plos.org/search?q=title:DNA';
    token;

    constructor(
        public http: HttpClient,
    ) {
    }


    getRequestHeader(): HttpHeaders {
        let authToken: string = "Default Token";
        const headers = new HttpHeaders();
        headers.set('content-type', 'application/json');
        headers.set('Authorization', 'DefaultToken');
        headers.set('Access-Control-Allow-Origin', "Origin");
        headers.set('Token', '12123123dwsww1qws2dwd23');
        return headers;
    }


    getCall(apiUrl): Observable<HttpResponse<Object>> {
        // this.token = localStorage.getItem('Token')
        // console.log('TOKEN : ' , this.token )
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': "application/json",
                'Access-Control-Allow-Origin': "Origin",
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }),
            observe: 'response' as 'response'
        };
        return this.http.get<HttpResponse<Object>>(environment.baseUrl + apiUrl, httpOptions).pipe(map((observe: 'text') => {
            return observe;
        }))
        // return this.http.get(environment.baseUrl + apiUrl, { observe: 'response' });
    }

    getViewDoc(url) {
        return this.http.get(environment.baseUrl + url, {
            'responseType': 'arraybuffer', headers: new HttpHeaders({
                'Access-Control-Allow-Origin': "Origin",
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            })
        });
    }

    postViewDoc(url, body) {
        return this.http.post(environment.baseUrl + url, body, {
            'responseType': 'arraybuffer', headers: new HttpHeaders({
                'Access-Control-Allow-Origin': "Origin",
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            })
        });
    }

    getBlobThumbnail(url): Observable<Blob> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        return this.http.post<Blob>(url,
            {
                "url": "http://acs/Logo.png"
            }, {headers: headers, responseType: 'blob' as 'json'});
    }

    postImageOrSoundCall(apiUrl) {
        return this.http.get(environment.baseUrl + apiUrl, {
            'responseType': "arraybuffer",//'text' as 'json',
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': "Origin",
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            })
        });
    }


    postCall(apiUrl, body): Observable<HttpResponse<Object>> {
        this.token = sessionStorage.getItem('Token')
        console.log('TOKEN : ', this.token)

        console.log('TOKEN sessionStorageq : ', sessionStorage.getItem('Token'))
        const httpOptions = {
            headers: new HttpHeaders({
                // "Accept": "*/*",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': "Origin",
                // 'authorization': 'Bearer',
                // 'Content-Type': 'application/json;charset=UTF-8',
                // 'Access-Control-Allow-Origin': "*",
                // 'Authorization': this.token || ''
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }),
            observe: 'response' as 'response'
        };
        console.log('httpoption', httpOptions);
        return this.http.post<HttpResponse<Object>>(environment.baseUrl + apiUrl, body, httpOptions).pipe(map((observe: 'response') => {
            return observe;
        }))
    }

    postCall1(apiUrl, body): Observable<HttpResponse<Object>> {
        this.token = sessionStorage.getItem('Token')
        console.log('TOKEN : ', this.token)

        console.log('TOKEN sessionStorageq : ', sessionStorage.getItem('Token'))
        const httpOptions = {
            headers: new HttpHeaders({
                // "Accept": "*/*",
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': "https://uat-prod.lokos.in",
                'Access-Control-Allow-Credentials':'true',
                'Access-Control-Allow-Methods':'*',
                'Access-Control-Allow-Headers':'*',
                // 'authorization': 'Bearer',
                // 'Content-Type': 'application/json;charset=UTF-8',
                // 'Access-Control-Allow-Origin': "*",
                // 'Authorization': this.token || ''
                // 'Authorization': 'No-Auth',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }),
            observe: 'response' as 'response'
        };
        console.log('httpoption', httpOptions);
        return this.http.post<HttpResponse<Object>>('/accounts.lokos.in/' + apiUrl, body, httpOptions).pipe(map((observe: 'response') => {
            return observe;
        }))
    }
    postImageCall(apiUrl, body, files): Observable<HttpResponse<Object>> {
        // console.log(' body ', body)
        // console.log(' files ', files)
        this.token = sessionStorage.getItem('Token')
        //  console.log('TOKEN : ' , this.token )
        const httpOptions = {
            headers: new HttpHeaders({
                // "Accept": "multipart/form-data",
                // "Accept": "application/json",
                'Access-Control-Allow-Origin': "Origin",
                // 'Authorization': this.token || ''
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }),
            observe: 'response' as 'response'
        };
        const formData: FormData = new FormData();
        var blob = new Blob([files], {type: 'multipart/form-data'});
        this.token = sessionStorage.getItem('Token')
        formData.append('file', blob, body.file);
        formData.append('applicationNumber', body.applicationNo);
        return this.http.post(environment.baseUrl + apiUrl, formData, httpOptions).pipe(map((observe: 'response') => {
            return observe;
        }))
    }

    getViewDocV2(url, reqObj) {
        return this.http.post(environment.baseUrl + url, reqObj, {
            'responseType': 'arraybuffer', headers: new HttpHeaders({
                'Access-Control-Allow-Origin': "Origin",
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            })
        });
    }

    postDocument(apiUrl, file): Observable<HttpResponse<Object>> {
        // console.log('UPLOAD IMAGE ', file)
        // console.log(' body ', body)
        // console.log(' files ', files)
        this.token = sessionStorage.getItem('Token')
        //  console.log('TOKEN : ' , this.token )
        const httpOptions = {
            headers: new HttpHeaders({
                // "Accept": "multipart/form-data",
                // "Accept": "application/json",
                // 'Access-Control-Allow-Origin': "*",
                // 'Authorization': this.token || ''
                'Access-Control-Allow-Origin': "Origin",
                // 'Authorization': this.token || ''
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }),
            observe: 'response' as 'response'
        };
        const formData: FormData = new FormData();
        //var blob = new Blob([files], {type : 'multipart/form-data'});
        this.token = sessionStorage.getItem('Token')

        if (file instanceof Array) {
            for (let i = 0; i < file.length; i++) {
                formData.append('file', file[i]);//blob, body.file);
            }
        } else {
            formData.append('file', file);//blob, body.file);
        }

        // formData.append('applicationNumber', body.applicationNumber);
        return this.http.post(environment.baseUrl + apiUrl, formData, httpOptions).pipe(map((observe: 'response') => {
            return observe;
        }))

    }


    postDocument1(apiUrl, body, file): Observable<HttpResponse<Object>> {
        // console.log('UPLOAD IMAGE ', file)
        // console.log(' body ', body)
        // console.log(' files ', files)
        this.token = sessionStorage.getItem('Token')
        //  console.log('TOKEN : ' , this.token )
        const httpOptions = {
            headers: new HttpHeaders({
                // "Accept": "multipart/form-data",
                // "Accept": "application/json",
                // 'Access-Control-Allow-Origin': "*",
                // 'Authorization': this.token || ''
                'Access-Control-Allow-Origin': "Origin",
                // 'Authorization': this.token || ''
                'Authorization': sessionStorage.getItem('Token') || '',
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache'
            }),
            observe: 'response' as 'response'
        };
        const formData: FormData = new FormData();
        //var blob = new Blob([files], {type : 'multipart/form-data'});
        this.token = sessionStorage.getItem('Token')

        if (file instanceof Array) {
            for (let i = 0; i < file.length; i++) {
                formData.append('file', file[i]);//blob, body.file);
            }
        } else {
            formData.append('file', file);//blob, body.file);
        }

        formData.append('applicationId', body.applicationId);
        return this.http.post(environment.baseUrl + apiUrl, formData, httpOptions).pipe(map((observe: 'response') => {
            return observe;
        }))

    }


    deleteCall(apiUrl, body): Observable<HttpResponse<Object>> {
        return this.http.delete<HttpResponse<Object>>(apiUrl, body).pipe(map((observe: HttpResponse<any>) => {
            return observe;
        }))
    }

    public putCall<T>(apiUrl: string, body: Object): Observable<HttpResponse<Object>> {
        // console.log('TOKEN : ' , this.token )
        this.token = sessionStorage.getItem('Token')
        const httpOptions = {
            headers: new HttpHeaders({
                // 'authorization': 'Bearer',
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': "*",
                // 'Authorization': this.token || ''
            }),
            observe: 'response' as 'response'
        };
        return this.http.put<HttpResponse<Object>>(apiUrl, body).pipe(map((observe: HttpResponse<any>) => {
            return observe;
        }));
    }


}
