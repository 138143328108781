import { Component, OnInit, Compiler, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SharedService } from '../../services/shared/shared.service';
import { UserManagementService } from '../../services/user-management/user-management.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Md5 } from 'ts-md5/dist/md5';
import { PATTER_VALIDATION } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';

import { COMMONTEXT } from '../../app.constants';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  profileForm: FormGroup;
  passwordForm: FormGroup;
  userDetails = {
    userId: '',
    oldUserId: '',
    userIdConsent: '',
    userName: '',
    userTelephone: ''
  }
  formSubmitted = false;
  formSubmit = false;
  viewProfileDetails;
  updateDisable = false;
  username;
  userdetailsName;
  changePasswordDetails = false;
  coursesPercentage: any;
  enableLoginButton = true;
  recaptchaResponse;
  enableRecaptcha = false;
  enableRecaptcha1 = false;

  @ViewChild('passwordPopup') public passwordPopup: ModalDirective;
  //enableFormDetails = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private userManagementService: UserManagementService,
    private _compiler: Compiler
  ) { }

  ngOnInit() {
    //  this.viewUserProfile();


    if(environment.baseUrl != "http://10.153.166.2/mofpi/"){
      this.enableRecaptcha = true;

    }else{
      this.enableRecaptcha = false;
    }

    this.profileForm = new FormGroup({
      userId: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      registrationDate: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      district: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),

    })

    this.passwordForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.pattern(PATTER_VALIDATION.PASSWORD_VALIDATION)] ),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern(PATTER_VALIDATION.PASSWORD_VALIDATION)]),
    })

    // console.log('PATTER_VALIDATION.PASSWORD_VALIDATION : ', PATTER_VALIDATION.PASSWORD_VALIDATION.hasNumber)


    $(function ($) {

      $(".sidebar-dropdown > a").click(function () {
        $(".sidebar-submenu").slideUp(300);
        if (
          $(this)
            .parent()
            .hasClass("active")
        ) {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .parent()
            .removeClass("active");
        } else {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .next(".sidebar-submenu")
            .slideDown(300);
          $(this)
            .parent()
            .addClass("active");
        }
      });



      $("#show-sidebar").click(function () {
        $(".page-wrapper").toggleClass("toggled");
      });

    });



    $(window).bind("resize", function () {
      if ($(this).width() < 1026) {
        $('.page-wrapper').removeClass('toggled');
      } else {
        $('.page-wrapper').addClass('toggled');

      }
    }).trigger('resize');


    $('.nav-icon').click(function () {
      $(this).toggleClass('open');
    });

    this.sharedService.getLocalStorageItem('userDetails').then((resp: any) => {
      if (resp) {
        this.userDetails.userName = resp.userName
        this.userdetailsName = resp
        //console.log('VIEWPROFILE : ' + JSON.stringify(this.userdetailsName))
        this.username = this.userdetailsName.name;
      }
    })



  }

  viewUserProfile() {

    this.userManagementService.viewUserProfile().then((resp: any) => {

      if (resp.status == 200) {
        if (resp['body']['responseCode'] == 200) {
          this.viewProfileDetails = resp['body']['responseObject'].userDetailsVO;
          //console.log('this.viewProfileDetails :' + JSON.stringify(this.viewProfileDetails));

          this.profileForm.controls['userId'].setValue(this.viewProfileDetails.userId);
          this.profileForm.controls['name'].setValue(this.viewProfileDetails.name);
          this.profileForm.controls['mobile'].setValue(this.viewProfileDetails.mobile);
          this.profileForm.controls['email'].setValue(this.viewProfileDetails.email);
          this.profileForm.controls['registrationDate'].setValue(this.viewProfileDetails.registrationDate);
          this.profileForm.controls['state'].setValue(this.viewProfileDetails.state.description);
          this.profileForm.controls['district'].setValue(this.viewProfileDetails.district.description);
          this.profileForm.controls['category'].setValue(this.viewProfileDetails.category.description);
          // this.username = this.viewProfileDetails.name;

        } else {
          this.sharedService.showError(resp['body']['responseDesc'])
        }
      }
    }, (error) => {
      // this.sharedService.showWarning(COMMONTEXT.API_ERROR)
    })

  }

  // closePopup(){
  //    this.passwordPopup.hide();
  // }

  updatePassword1() {
    // this.passwordPopup.config = { backdrop: 'static', keyboard: false }

    this.formSubmitted = true;
    if (this.passwordForm.valid) {

      this.updatePassword();
    }
  }

  updatePassword() {
    const md5 = new Md5();
    const md6 = new Md5();
    const md7 = new Md5();
    if (this.passwordForm.controls['oldPassword'].value == this.passwordForm.controls['newPassword'].value) {
      this.sharedService.showError("New Password cannot be same as Old Password");
    } else {
      const obj = {
        oldPassword: md5.appendStr(this.passwordForm.controls['oldPassword'].value).end(),
        newPassword: md6.appendStr(this.passwordForm.controls['newPassword'].value).end(),
        confirmPassword: md7.appendStr(this.passwordForm.controls['confirmPassword'].value).end(),
      }
      this.userManagementService.changePassword(obj).then((resp: any) => {

        if (resp.status == 200) {
          if (resp['body']['responseCode'] == 200) {

            this.sharedService.showSuccess(resp['body']['responseDesc']);
            this.closepwd();
            this.logOut();
          } else {
            this.sharedService.showError(resp['body']['responseDesc'])
          }
        }
      }, (error) => {
        this.sharedService.showWarning(COMMONTEXT.API_ERROR)
      })
    }

  }

  dierectlogOut() {
    this.sharedService.confirm(COMMONTEXT.CONFIRMATION_HEADER, COMMONTEXT.LOGOUT, 'md').then((resp: any) => {

      console.log('resp ' , resp)
      if(resp) {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            reload: true
          }
        };
        this.userManagementService.logout().then((resp: any) => {
          if(resp.status == 200) {
            if(resp['body']['responseCode'] == 200) {
              this.router.navigateByUrl('/Login', navigationExtras)
            }
          }
        })
      } 
    }) 
  }

  logOut() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        reload: true
      }
    };
    this.router.navigateByUrl('/Login', navigationExtras)
    // this.router.navigate(['/Login'])
    // location.reload();
  }

  // changePassword() {
  //   this.formSubmitted = false;
  //   this.passwordPopup.show();
  // }

  // closeChangePassword() {
  //   this.formSubmitted = false;
  //   this.passwordPopup.hide();
  //   this.passwordForm.reset();
  // }

  changePasswordPopUp() {
    this.changePasswordDetails = true;
    this.recaptchaResponse = undefined;
    this.formSubmitted = false;
    this.passwordForm.reset();
    this.enableRecaptcha1 = false;

  

  }

  closepwd() {
    this.changePasswordDetails = false;
    this.formSubmitted = false;
    this.passwordForm.reset();
  }
  resolved(recaptchaResponse: string) {

    this.recaptchaResponse = recaptchaResponse
    if (recaptchaResponse) {
      this.enableLoginButton = false;
      this.enableRecaptcha1 = true;

    } else {
      this.enableLoginButton = true;
      this.enableRecaptcha1 = false;

    }
    console.log('Recaptcha ', recaptchaResponse)
  }



}
