import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { error } from 'protractor';
import { NgxSpinnerService } from "ngx-spinner";
import { resolve } from 'url';
import * as CryptoJS from 'crypto-js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  userRoles;
  classList = [];
  secretKey = "Ge^3%kanLas&1@j!";
  encryptSecretKey = CryptoJS.enc.Utf8.parse('Ge^3%kanLas&1@j!');
  vector = CryptoJS.enc.Utf8.parse('Jha&82u)9G!lh#jp');
  operator;
  apiCheckFlag = true;
  constructor(
    private sanitizer: DomSanitizer,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  setLocalStorageItem(key, value) {
    return new Promise((resolve, reject) => {
      try {
        sessionStorage.setItem(key, value);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  getLocalStorageItem(key) {
    return new Promise((resolve, reject) => {
      try {
        let resJson = JSON.parse(sessionStorage.getItem(key));
        resolve(resJson);
      } catch (e) {
        reject(e);
      }
    });
  }

  clearLocalStorageItem(key) {
    return new Promise((resolve, reject) => {
      try {
        sessionStorage.removeItem(key);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  separateTimeAndTimeFormat(str: string, delimeter: string): any[] {
    if (typeof str === undefined) {
      return [];
    } else {
      return str.split(delimeter);
    }
  }

  convertDateToTimeFormat(date: string): string {
    var recievedDate = new Date(date);
    var options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return recievedDate.toLocaleString('en-US', options);
  }

  setLocalStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  showLoader() {
    this.spinnerService.show();
  }

  hideLoader() {
    this.spinnerService.hide();
  }

  showSuccess(message) {
    this.toastr.success(message, '', {
      timeOut: 3000,
      positionClass: 'toast-top-right'
    });
  }

  showInfo(message) {
    this.toastr.info(message, 'Info!', {
      timeOut: 3000,
      positionClass: 'toast-top-right'
    });
  }

  showWarning(message) {
    this.toastr.warning(message, 'Warning !', {
      timeOut: 3000,
      positionClass: 'toast-top-right'
    });
  }

  showError(message) {
    this.toastr.error(message, 'Error!', {
      timeOut: 3000,
      positionClass: 'toast-top-right'
    });
  }

  showMsg(message) {
    this.toastr.warning(message, 'No Images', {
      timeOut: 3000,
      positionClass: 'toast-top-right'
    });
  }

  formatDate<T>(val, formaTType) {
    return new Promise((resolve, reject) => {
      try {
        var selectedDate;
        if (val !== null && val.year) {
          selectedDate = val.year + "-" + val.month + "-" + val.day
        } else {
          selectedDate = val
        }
        if (moment(selectedDate, formaTType).isValid()) {
          resolve(selectedDate)
        } else {
          reject();
        }
      } catch (error) {
      }
    })
  }

  formatTodayDate<T>(today) {
    return new Promise((resolve, reject) => {
      try {
        var selectedDate;
        let month;
        let day;
        if (today !== null) {
          if (Number(today.getMonth()) + 1 < 10) {
            month = '0' + (Number(today.getMonth()) + 1)
          } else {
            month = Number(today.getMonth()) + 1
          }
          if (today.getDate() < 10) {
            day = '0' + today.getDate()
          } else {
            day = today.getDate()
          }
          selectedDate = today.getFullYear() + '-' + month + '-' + day;
        }
        if (selectedDate) {
          resolve(selectedDate)
        } else {
          reject();
        }
      } catch (error) {
      }
    })
  }

  searchFromArray<T>(searchedArray, searchedKey, searchedValue) {
    return new Promise((resolve, reject) => {
      try {
        const response = searchedArray.find(val => val.searchedKey === searchedValue);
        if (response != null && response != undefined) {
          resolve(response)
        } else {
          reject();
        }
      } catch (error) {
      }
    })
  }

  loaderWithTimer() {
    // this.spinnerService.show(setTimeout(() => {

    // }, 500);;
    // )}
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide()
    }, 1000);
  }

  clearAllLocalStorage() {
    sessionStorage.clear();
  }

  getUserRole() {
    return this.userRoles;
  }

  setUserRole(roles) {
    this.userRoles = roles;
  }

  generateClassList(group) {
    this.classList = [];
    return new Promise((resolve, reject) => {
      try {
        for (let i = 0; i < 45; i++) {
          const classObj = {
            id: i + group,
            class: i + 1
          }
          this.classList.push(classObj)
        }
        if (this.classList.length == 45) {
          resolve(this.classList);
        }
      } catch (error) {

      }
    })
  }

  deleteFromArray(array, obj) {
    this.classList = [];
    return new Promise((resolve, reject) => {
      try {
        const index = array.indexOf(obj);
        if (index > -1) {
          array.splice(index, 1);
          resolve(this.classList);
        }
      } catch (error) {

      }
    })
  }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }

  aesEncryption(input) {
    return CryptoJS.AES.encrypt(JSON.stringify(input), this.encryptSecretKey, {
      iv: this.vector,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  aesDecryption(input) {
    return CryptoJS.AES.decrypt(input, this.encryptSecretKey, {
      iv: this.vector,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }


  setFontSize(val) {
    this.operator = val;
    // console.log('setFontSIze:', val)

  }



  getFontSize() {

    return new Promise((resolve, reject) => {

      try {

        let resJson = this.operator;

        resolve(resJson);
        // console.log('getFontSIze:', resJson)

      } catch (e) {

        reject(e);

      }

    });

    // return this.operationValue

  }


  confirm(
    title: string,
    message: string,
    dialogSize): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: dialogSize, backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = 'OK';
    modalRef.componentInstance.btnCancelText = 'Cancel';
    return modalRef.result;
  }



  setApiStatus(val) {
    this.apiCheckFlag = val;
    console.log('setApiStatus:', val)

  }



  getApiStatus() {

    return new Promise((resolve, reject) => {

      try {

        let resJson = this.apiCheckFlag;

        resolve(resJson);
        console.log('getApiStatus:', resJson)

      } catch (e) {

        reject(e);

      }

    });

    // return this.operationValue

  }
}
