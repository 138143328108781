import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BaseServiceService } from './services/base-service/base-service.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component'
import { NgxPaginationModule } from 'ngx-pagination'
/*Views End*/
import { Ng2SearchPipeModule } from 'ng2-search-filter';
/*Component Start*/
import { HttpErrorInterceptor } from './services/common/app-error-handler.service';
import { AppCustomPreloader } from './app-routing-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { RecaptchaModule } from "ng-recaptcha";
import { MilletPhotoComponent } from './components/millet-photo/millet-photo.component';
import { HomePageWhatsNewComponent } from './components/home-page-whats-new/home-page-whats-new.component';
// import { ConvergenceMoaComponent } from './components/convergence-moa/convergence-moa.component';
// import { ConvergenceMordComponent } from './components/convergence-mord/convergence-mord.component';
// import { ConvergenceMohuaComponent } from './components/convergence-mohua/convergence-mohua.component';
// import { ConvergenceMotaComponent } from './components/convergence-mota/convergence-mota.component';
// import { HomePageConvergenceComponent } from './components/home-page-convergence/home-page-convergence.component';






@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SideBarComponent,
        FooterComponent,
        ConfirmationDialogComponent,
        // ConvergenceMoaComponent,
        // ConvergenceMordComponent,
        // ConvergenceMohuaComponent,
        // ConvergenceMotaComponent,
        // HomePageConvergenceComponent,
    
        // MilletPhotoComponent,
      
        
      
       

    ],
    entryComponents: [
        ConfirmationDialogComponent
    ],
    imports: [
        // NgxPrintModule,
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        HttpClientModule,
        Ng4LoadingSpinnerModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            closeButton: true
        }),
        ModalModule.forRoot(),
        NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        BackButtonDisableModule.forRoot({
            preserveScrollPosition: false
        }),
        NgbModule.forRoot()
    ],
    providers: [BaseServiceService, AppCustomPreloader,
        {
            provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor,
            multi: true
        }],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
