import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BaseServiceService } from '../base-service/base-service.service';
import { Http, Headers } from '@angular/http';
import { ServerResponse } from 'src/app/models/server-response'
import { APPLICATION_CONTROLLER, COMMON_CONTROLLER } from '../../app.constants';
@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseServiceService {

  constructor(http: HttpClient) {
    super(http);
  }


  updateUser(obj) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/updateUser';
        this.postCall(apiName, obj).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  widrawApplication(obj) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/widrawApplication';
        this.postCall(apiName, obj).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getApplicationDetails() {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/v2/getApplications';
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getApplicationDetailsNRLMInd() {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/v2/getApplications';
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getShgApplicantId() {
    console.log('here')
    return new Promise((resolve, reject) => {
      try {
        let apiName = APPLICATION_CONTROLLER.GET_SHG_APPLICANTID;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getCommonInfraApplicantId() {
    return new Promise((resolve, reject) => {
      try {
        let apiName = COMMON_CONTROLLER.GET_COMMON_INFRA_APPLICANTID + "COMINF";
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }



  getApplicantDetails(applicantId) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/v2/getApplicantId/' + applicantId;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }
  getApplicantDetailsNRLM() {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/common/getUserApplication/';
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getProposedBusinessDetails(applicantId) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/v2/getProposalDetails/' + applicantId;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getProposedFinancialDetails(applicantId) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/getProposedFinancialDetails/' + applicantId;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getLt1crExistFinancialDetailsDraft(applicantId) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/getLt1crExistFinancialDetailsDraft/' + applicantId;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getMt1crExistFinancialDetailsDraft(applicantId) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/getMt1crExistFinancialDetailsDraft/' + applicantId;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  getExistEnterpriseDetails(applicantId) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/v2/getExistEnterpriseDetails/' + applicantId;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }


  getLendingBankDetails(applicantId) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/application/getLendingBankDetails/' + applicantId;
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }


  // updateUser1(obj) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       let apiName = 'api/users/updateUser';
  //       this.postCallForUpdateProfile(apiName, obj).subscribe((data) => {
  //         if (data != null && data != undefined) {
  //           resolve(data);
  //         } else {
  //           reject();
  //         }
  //       }, (error) => {
  //         reject(error);
  //       })
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }


}
