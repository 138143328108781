import { AbstractControl, ValidationErrors } from "@angular/forms";
import { SharedService } from "./services/shared/shared.service";

export class Helper {
  static isNextStep: boolean;
}

// var TextBox.innerHTML = "Hello";
export const COMMONTEXT = {
  // var TextBox.innerHTML = "Hello",
  INCOMPLETE_FORM: 'Please complete the form',
  API_ERROR: 'Something went wrong, please try after sometime',
  SUCCESS_MESSAGE: 'Your Registration is successful, Please login to Proceed Further',
  CHECK_ACK: 'Please select acknowledge first to proceed',
  CHECK_ACK_REPORT: 'Please your acknowledge for report',
  CHECK_COMPLETE_ACK: 'Please fill and confirm the complete declaration form',
  VAL1: 'If Agriculture Produce, is the Product Manufactured same as ODOP Identified by State for the District?',
  VAL2: 'If Food Product, is the Product Manufactured same as ODOP Identified by State for the District?',
  VAL3: 'If Others, Enter the Name of Product',
  MAX_BANK: 'Maximum 2 banks can be added',
  MAX_BANK1: 'Maximum 5 Bank can be added',
  MAX_BANK2: 'Maximum 1 Bank can be added',
  VAL4: 'If MFP, Product Manufactured same as ODOP identified by State for the district',
  VAL1H: 'यदि कृषि उत्पादन, उत्पाद जिले के लिए राज्य द्वारा चिह्नित ओ.डी.ओ.पी. के रूप में ही निर्मित है',
  VAL2H: 'यदि खाद्य उत्पाद, उत्पाद जिले के लिए राज्य द्वारा पहचाने गए ओ.डी.ओ.पी. के समान है',
  VAL3H: 'यदि अन्य, उत्पाद का नाम दर्ज करें',
  VAL4H: 'यदि एमएफपी, उत्पाद का निर्माण जिले के लिए राज्य द्वारा पहचाने गए ओ.डी.ओ.पी. के रूप में किया जाता है',
  NOT_FOUND: 'Applicant details not saved',
  VAL5: 'If Not Listed, Enter the Name of Product',
  VAL5H: 'यदि सूचीबद्ध नहीं है, तो उत्पाद का नाम दर्ज करें',
  // VAL6: 'If Agriculture Produce, is the Product Manufactured same as ODOP Identified by State for the District?',
  VAL6H: 'यदि कृषि उत्पादन, उत्पाद जिले के लिए राज्य द्वारा चिह्नित ओ.डी.ओ.पी. के रूप में ही निर्मित है',
  VAL7: 'Please Submit Applicant Details',
  VAL8: 'Please select Declaration Form and Place',
  VAL9: 'Please Submit All the Forms',
  VAL10: 'Please Add Banks',
  VAL11: 'If MFP, Select the Product.',
  VAL12: 'If Agriculture Product, select Product being Manufactured is same ODOP Identified by State?',
  VAL13: 'If Food Product, select Product being Manufactured is same ODOP Identified by State?',
  VAL14: 'Please enter the name of the product being Manufactured',
  NEWIN: 'Individual New Food Processing Enterprise',
  LT1CR: 'Individual Applicant (Business Turnover Less than 1 Cr)',
  MT1CR: 'Individual Applicant (Business Turnover More than 1 Cr)',
  FILE: 'Please select File',
  NET_PROFIT_RATIO: 'Reassess the sales & cost figures and enter realistic values. The firm should generate sufficient profit to sustain after paying loan obligations.',
  DSCR_NG: 'Increase the repayment period. It should normally be in the range of 5-7 years.',
  NET_PROFIT_NG: 'Please review your expenses, as your proposed expenses (Raw material, wages, production cost and administrative cost) are higher than proposed sales.',
  COMMON_ERROR: 'It seems that your project report is not viable and the same can be rejected by the bank.',
  NET_PROFIT_NG_STRONG: 'Net Profit is not positive: ',
  NET_PROFIT_RATIO_STRONG: 'Net profit ratio less than 3 %: ',
  DSCR_NG_STRONG: 'Net profit positive and average DSCR ratio is negative: ',
  CANT_PREVIEW: 'Something went wrong',
  CONFIRMATION_HEADER: 'Alert',
  SECURITY_MESSAGE: 'You are not allowed to access this module',
  SIZE: 'md',
  CONFIRMATION_APP_SUB_Header: 'Are you sure you want to submit?',
  CONFIRMATION_APP_MSG: 'Once saved, data cannot be modified.',
  LOGOUT: 'Do you really want to logout?',
  CONCURRENT_LOGIN: 'User login from different browser',
  UN_AUTH: 'Unauthenticated Request',
};

export const USED_FOR = {
  FIN: 'FIN'
}

export const APP_RELOAD = {
  TIMER: 1800000,
};

export const PAGINATION_PARAMETERS = {
  PAGE: 1,
  PAGE_SIZE: 3
}

export const IMAGE_URL = {
  PATH: '10.62.0.158/homepage-image'
}

export const PATTER_VALIDATION = {
  // ^([a-zA-Z ]){2,30}$
  NAME: '^([a-zA-Z ]){2,100}$',
  ALPHA_NUM: '^[a-zA-Z0-9_]*$',
  PHONE: '^[0-9]{1,25}$',
  // EMAIL: '[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}',
  //EMAIL: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
  EMAIL: '[a-z0-9_!#$%&*+.-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
  DIGI10TPHONE: '^[0-9]{10,10}$',
  AADHAR: '^[0-9]{12,12}$',
  PIN: '^[0-9]{6,6}$',
  NUMBER: '^[0-9]{0,9}$',
  // PAN: '^[a-zA-Z0-9.-]{10 ,10}$',
  PAN: '[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}',
  UDYOG_AADHAR: '[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{1}[0-9]{7}',
  UDYAM_NUMBER: '[UDYAMudyam]{5}[a-zA-Z]{2}[0-9]{13}',
  UDYOG_REGISTRATION_NUMBER: '[a-zA-Z]{5}[-]{1}[a-zA-Z]{2}[-]{1}[0-9]{2}[-]{1}[0-9]{7,8}',
  ALPHA: '[a-zA-Z0-9.-]{1,11}$',
  GSTIN: '[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[a-zA-Z0-9]{3}',
  FSSAI: '[0-9]{14}',
  // URL: "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$"
  URL: "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$",
  AADHAR_OTP: "^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$",
  PASSWORD_VALIDATION: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  // latiLongi:'[-+]?[0-9]{2}\.[0-9]{3,10}$',
  // latiLongi:'[-+]?[0-9]{2}[\.]?[0-9]{3,10}$',
  latiLongi:'[0-9]{2}[.]{1}[0-9]{3,10}$',
  

  // SYMBOLS: /[!@#$%^&*]{1,16}$/,
  // LOWER_LETTERS:/[a-z]{1,16}$/, 
  // UPPER_LETTERS: /[A-Z]{1,16}$/, 
  // numbers: /[0-9]{1,16}$/, 

  // SITE_SUBSITE_URL: "10.62.0.134/",
  // SITE_SUBSITE_URL: "10.153.166.2/"
  SITE_SUBSITE_URL: "pmfme.mofpi.gov.in/",
}

// export const APPMANAGEMENT = {
//   SAVE_APPLICANT: 'api/application/saveApplicant/',

// };


export class CustomValidators {
  static invalidCode(control: AbstractControl): ValidationErrors | null {
    return { invalidCode: true };
  }


  static emailValidation(control: AbstractControl): ValidationErrors | null {

    if (control.value) {
      var reg = new RegExp(PATTER_VALIDATION.EMAIL);

      if (!reg.test(control.value)) {
        return { invalidEmail: true }
      }
    }

  }

  static phoneNumberValidation(control: AbstractControl): ValidationErrors | null {

    if (control.value) {
      var reg = new RegExp(PATTER_VALIDATION.PHONE);

      if (!reg.test(control.value)) {
        return { invalidPhoneNumber: true }
      }
    }

  }

  static notGreaterthanTodaysDate(control: AbstractControl): ValidationErrors | null {

    let selectedDate;
    try {
      selectedDate = new Date(control.value.year, control.value.month, control.value.day, 0, 0, 0, 0);
    }
    catch (error) {
      selectedDate = new Date();
    }
    let current = new Date();
    let todaysDate = new Date(current.getFullYear(), current.getMonth() + 1, current.getDate(), 0, 0, 0, 0);

    let timeDifference = todaysDate.getTime() - selectedDate.getTime();
    if (timeDifference < 0)
      return { invalidFutureDate: true };
  }

  static minimun21AgeValidation(control: AbstractControl): ValidationErrors | null {

    try {
      if (!(new Date(control.value.mm - 1, control.value.dd, control.value.yyyy + 18) <= new Date()))
        return { invalidMinimum21Age: true };
    }
    catch (ex) {
      return null;
    }
  }

  static minimun18AgeValidation(control: AbstractControl): ValidationErrors | null {

    try {
      if (!(new Date(control.value.year + 18, control.value.month - 1, control.value.day) <= new Date()))
        return { invalidMinimum18Age: true };
    }
    catch (ex) {
      return null;
    }
  }

  static pastDateValidation(control: AbstractControl): ValidationErrors | null {

    let selectedDate;
    try {
      selectedDate = new Date(control.value.year, control.value.month, control.value.day, 0, 0, 0, 0);
    }
    catch (error) {
      selectedDate = new Date();
    }
    let current = new Date();
    let todaysDate = new Date(current.getFullYear(), current.getMonth() + 6, current.getDate(), 0, 0, 0, 0);

    let timeDifference = todaysDate.getTime() - selectedDate.getTime();
    if (timeDifference <= 0)
      return { invalidFutureDate: true };
  }

}

export const APPLICATION_CONTROLLER = {
  UPDATE_STATUS: 'api/application/updateStatus',
  SAVE_IND_EXT_ENTERPRISE_DETAIL: 'api/application/saveIndExtEnterpriseDetail',
  SAVE_IND_EXT_ENTERPRISE_DETAIL_V2: 'api/application/v2/saveIndExtEnterpriseDetail',
  //SAVE_PROPOSAL: 'api/application/saveProposal',
  SAVE_PROPOSAL: 'api/application/v2/saveProposal',
  SAVE_MT1CR_FINANCIAL_DETAILS: 'api/application/saveMT1CRFinancialDetails',
  SAVE_APPLICANT: 'api/application/saveApplicant',
  SAVE_APPLICANTV2: 'api/application/v2/saveApplicant',
  GET_LANDING_BANK_DETAILS: 'api/master/getLandingBankDetails', // moved in master controller, kindly check if this controller is not used been used in any of the module remove it
  SAVE_LANDING_BANK: 'api/application/saveLandingBank',
  SAVE_LTR1CR_FINANCIAL_DETAILS: 'api/application/saveLT1CRFinancialDetails',
  GET_ODOP_PRODUCT: 'api/master/getOdopProduct/districtCode/',
  // SAVE_LENDING_BANK: 'api/application/saveLendingBank',
  SAVE_LENDING_BANK: 'api/application/v2/saveLendingBank',
  SAVE_LAT_LONG: 'api/application/saveLatLong',
  GET_SHG_TYPE: 'api/master/getShgType', // moved in master controller
  GET_DESIGNATION: 'api/master/getDesignation',
  GET_SHG_LOANTYPE: 'api/master/getShgLoanType', // moved in master controller
  GET_SHG_INFRA: 'api/master/getShgInfraType', // moved in master controller
  GET_SHG_LEASE: 'api/master/getShgLeasing', // moved in master controller
  GET_LENDING_BANK_DETAILS: 'api/application/getLendingBankDetails/',
  GET_SHG_APPLICANTID: 'api/common/getUserApplication/',
  GET_NOTIFICATION_APPLICANTID: 'api/common/getUserApplication/',
  GET_BUSINESS_CLASSIFICATION: 'api/master/getShgClassification', // moved in master controller
  GET_GRADATION: 'api/master/getMasterFpoGrade', // moved in master controller
  // SAVE_GRIEVANCE: 'api/grievances/addGrievance',
  // GET_GRIEVANCE_LIST: 'api/grievances/getGrievancesByUser/',
  // GET_GRIEVANCE_BY_ID: 'api/grievances/getGrievancesById/',
  GET_NOTIFICATION: 'api/users/getNotificationList',
  GET_NOTIFICATION_V2: 'api/users/getNotificationList/v2/',
  GET_NOTIFICATION_EMAIL: 'api/users/getNotificationEmailDetails/',
  GET_PROJECTED_FIN: 'api/application/getProjectedFin/',
  GET_LANDING_BANK_DETAILS1:'api/master/getLandingBankDetails/'
}

export const GRIEVANCE_CONTROLLER = {
  SAVE_GRIEVANCE: 'api/grievances/addGrievance',
  GET_GRIEVANCE_LIST: 'api/grievances/getGrievancesByUser/',
  GET_GRIEVANCE_BY_ID: 'api/grievances/getGrievancesById/',
  SAVE_RAISE_ESCALATION: 'api/grievances/raiseEscalation',
  UPDATE_GRIEVANCE_DOCUMENTID: 'api/grievances/updateGrievanceDocumentId'
}

export const SHG_CONTROLLER = {
  //SAVE_SHG_PROFILE: 'api/shg/saveShgProfile',
  SAVE_SHG_PROFILE: 'api/shg/v2/saveShgProfile',
  SAVE_PROPOSED_PROFILE: 'api/shg/saveProposedPlan',
  SAVE_EXISTING_PROFILE: 'api/shg/saveExistOperationDetails',
  //GET_SHG_APPLICANT_DETAILS: 'api/shg/getShgProfile/',
  GET_SHG_APPLICANT_DETAILS: 'api/shg/v2/getShgProfile/',
  SAVE_MICRO_PROFILE: 'api/shg/saveMicroCreditHistory',
  GET_SHG_MICRO_APPLICANT_DETAILS: 'api/shg/getMicroCreditHistory/',
  GET_EXISTING_APPLICANT_DETAILS: 'api/shg/getExistOperationDetails/',
  GET_PROPOSED_DETAILS: 'api/shg/getProposedPlan/',
  GET_SHG_DESIGNATION: 'api/master/getMasterDesignation/',
  // GET_SHG_BLOCKID:'api/shg/getShgListByBlockId'
}

export const HOME_CONTROLLER = {
  GET_HOME_PAGE_INFO: 'api/home/getHomePageInfo',
  GET_WHOS_WHO_INFO: 'api/home/getWhosWhoInfo',
  GET_ASSETS: 'api/home/getAssets/',
  GET_DOWNLOADS: 'api/home/getDownloads/',
  GET_MEDIA_COVERAGE: 'api/home/getMediaCoverage',
  GET_SUB_PHOTO_GALLERY:'api/home/getSubGalleryDetails/',
  GET_BUILD_AND_RELEASES:'api/mis/getAllBuildAndReleases',
  GET_DASHBOARD_KPI:'api/kpi/getCountsYearWise',
  GET_STATE_DASHBOARD_KPI:'api/kpi/getStateWiseApplicationCounts',
  GET_DISTRICT_DASHBOARD_KPI:'api/kpi/getDistrictWiseApplicationCounts'
}





export const COOPERATRIVE_CONTROLLER = {
  GET_EXISTING_DETAIL: 'api/cooperative/v2/getExistingDetail/',
  GET_COOP_PROPOSAL_DETAIL: 'api/cooperative/getCoopProposalDetail/',
  SAVE_COOP_PROPOSAL_DETAIL: 'api/cooperative/saveCoopProposalDetail',
  SAVE_EXISTING_DETAIL: 'api/cooperative/v2/saveExistingDetail',
  GET_SOCIETY_DETAIL: 'api/cooperative/v2/getSocietyDetail/',
  SAVE_SOCIETY: 'api/cooperative/saveSociety',
  SAVE_SOCIETY_V2: 'api/cooperative/v2/saveSociety'
}

export const COMMON_INFRA_CONTROLLER = {
  SAVE_COMMON_INFRA_PROFILE: 'api/commonInfra/saveCommonInfraDetails',
  SAVE_COMMON_INFRA_PROFILE_V2: 'api/commonInfra/v2/saveCommonInfraDetails',
  SAVE_EXISTING: 'api/commonInfra/saveCommonInfraExistOperation',
  SAVE_PROPOSED: 'api/commonInfra/saveCommonInfraProposedDetails',
  GET_COMMON_INFRA_APPLICANT_DETAILS: 'api/commonInfra/getCommonInfraDetails/',
  GET_COMMON_INFRA_APPLICANT_DETAILS_V2: 'api/commonInfra/v2/getCommonInfraDetails/',
  GET_COMMON_INFRA_EXISTING_DETAILS: 'api/commonInfra/getComInfraExistOperationDetails/',
  GET_COMMON_INFRA_PROPOSED_DETAILS: 'api/commonInfra/getCommonInfraProposedDetails/',
  SAVE_FPO_ENTITY: 'api/commonInfra/saveFpoEntityDetails',
  SAVE_SHG_ENTITY: 'api/commonInfra/saveShgEntityDetails',
  SAVE_COOP_ENTITY: 'api/commonInfra/saveCoopEntityDetails',
  GET_FPO_ENTITY_DETAILS: 'api/commonInfra/getFpoEntityDetails/',
  GET_SHG_ENTITY_DETAILS: 'api/commonInfra/getShgEntityDetails/',
  GET_COOP_ENTITY_DETAILS: 'api/commonInfra/getCoopEntityDetails/',
}

export const FPC_CONTROLLER = {

  //SAVE_FPC_PROFILE: 'api/fpo/saveFpoDetails',
  SAVE_FPC_PROFILE: 'api/fpo/v2/saveFpoDetails',
  //GET_FPC_APPLICANT_DETAILS: 'api/fpo/getFpoDetails/',
  GET_FPC_APPLICANT_DETAILS: 'api/fpo/v2/getFpoDetails/',
  SAVE_FPC_EXISTING: 'api/fpo/v2/saveFpoExistingOperation',
  //SAVE_FPC_EXISTING: 'api/fpo/saveFpoExistingOperation',
  GET_FPC_EXISTING_APPLICANT_DETAILS: 'api/fpo/v2/getFpoExistingOperation/',
  GET_FPC_PROPOSED_APPLICANT_DETAILS: 'api/fpo/getFpoProposedBusinessDetails/',
  SAVE_FPC_PROPOSED_PROFILE: 'api/fpo/saveProposedBusinessDetails',
}


export const USERS_CONTROLLER = {
  VIEW_INTERNAL_USER_PROFILE: '/api/users/viewInternalUserProfile'
}

export const COMMON_CONTROLLER = {
  GET_COMMON_INFRA_APPLICANTID: 'api/common/getUserApplication/',
}

export const MASTER_CONTROLLER = {
  GET_STATES: 'api/master/getStates',
  GET_APPLICANTS_CATEGORY: 'api/master/getApplicantsCategory',
  GET_GENDER: 'api/master/getGender',
  GET_QUALIFICATION: 'api/master/getQualification',
  GET_PRODUCT_TYPE: 'api/master/getProductType/',
  GET_OTHER_ODOP: 'api/master/getOtherOdop',
  GET_MFP_PRODUCTS: 'api/master/getMfpProduts',
  GET_QUALITY_CERTIFICATION: 'api/master/getQualityCertification',
  GET_NATURE_WORKER: 'api/master/getNatureWorker',
  GET_DOCUMENT_TYPE: 'api/master/getDcoumentType',
  GET_DISTRICT: 'api/master/getDistricts/',
  GET_PROPERTY_TYPE: 'api/master/getPropertyType',
  GET_APPLICANT_CAST: 'api/master/getApplicantCast',
  GET_BUILDING_TYPE: 'api/master/getSocietyInfraDetails/IND',
  GET_APP_SUB_CATEGORY: 'api/master/getAppSubCategory/',
  GET_WORKER_TYPE: 'api/master/getWorkerType/',
  GET_LOAN_STATUS: 'api/master/getLoanStatus',
  GET_LOAN_STATUS_MORE: 'api/master/getMasterLoanStatus/INDL',

  GET_LOAN_STATUS_FPC: 'api/master/getMasterLoanStatus/',
  // GET_LOAN_STATUS_SHG: 'api/master/getMasterLoanStatus/SHG',
  // GET_LOAN_STATUS_COOP: 'api/master/getMasterLoanStatus/COOP',
  GET_LEGAL_STATUS: 'api/master/getLegalStatus',
  GET_LOAN_TYPE: 'api/master/getLoanType',
  GET_MASTER_LOAN_TYPE: 'api/master/v2/getLoanType/IND',
  GET_REPAYMENT_STATUS_TYPE: 'api/master/getRepayTimeFrameList/IND',
  GET_FINANCIAL_PERIOD: 'api/master/getFinancialPeriod',
  GET_ODOP_PRODUCT: 'api/master/getOdopProduct/',
  GET_OTHER_ODOP_PRODUCT: 'api/master/getOdopOtherProduct/',
  SAVE_FINANCIAL: 'api/application/saveFinancialDetails',
  //GET_APPLICATION: 'api/application/getApplications',
  GET_APPLICATION: 'api/application/v2/getApplications',
  UPLOAD_DOC: 'api/digitization/uploadDocument/',
  GET_DOC_DMS: 'api/digitization/getDocumentDMS',
  DELETE_DOC: 'api/digitization/deleteDocument',
  //GET_APPLICANT: 'api/application/getApplicantId/',
  GET_APPLICANT: 'api/application/v2/getApplicantId/',
  GET_SANCTIONED_APPLICATIONS: 'api/application/getApplicationDetails/',
  GET_UPDATED_LOCATION: 'api/application/getUpdatedLatLong',

  //GET_PROPOSAL: 'api/application/getProposalDetails/',
  GET_PROPOSAL: 'api/application/v2/getProposalDetails/',
  GET_PROPOSED_FINANCIAL: 'api/application/getProposedFinancialDetails/',
  GET_LENDING_BANK_DETAILS: 'api/application/getLendingBankDetails/', // moved in application controller
  GET_EXISTING_DETAILS: 'api/application/getExistEnterpriseDetails/',
  GET_EXISTING_DETAILS_V2: 'api/application/v2/getExistEnterpriseDetails/',
  GET_MT1_EXISTING_FINANCIAL_DETAILS: 'api/application/getMt1crExistFinancialDetailsDraft/',
  GET_LT1_EXISTING_FINANCIAL_DETAILS: 'api/application/getLt1crExistFinancialDetailsDraft/',
  GET_GRADATION: 'api/master/getMasterFpoGrade',
  GET_COOP_INFRA_DETAILS: 'api/master/getCoopInfraDetails',
  GET_SHG_LEASING: 'api/master/getShgLeasing',
  GET_DESIGNATION: 'api/master/getDesignation',
  GET_SHG_UNIT: 'api/master/getMasterSiUnit/',

  GET_LANDING_BANK_DETAILS: 'api/master/getLandingBankDetails',
  GET_BUSINESS_CLASSIFICATION: 'api/master/getShgClassification/',
  GET_SHG_INFRA: 'api/master/getCoopInfraDetails',
  GET_SOCIETY_INFRA: 'api/master/getSocietyInfra',
  DOWNLOAD_REPORT: 'api/reports/getViewExcelReport',
  DOWNLOAD_REPORT_IND: 'api/reports/getReportByApplicantId/',
  DOWNLOAD_REPORT_MT1CR: 'api/reports/getMoreThan1CRReportByApplicantId/',
  DOWNLOAD_REPORT_LT1CR: 'api/reports/getLessThan1CRReportByApplicantId/',
  DOWNLOAD_REPORT_SHG: 'api/reports/getSHGReport/',
  GET_BORROWER_LIST: 'api/master/getMasterLegalStatus/',
  GET_SHG_TYPE: 'api/master/getShgType',
  GET_SHG_LOANTYPE: 'api/master/getShgLoanType/',
  GET_SHG_INFRATYPE: 'api/master/getShgInfraType',
  GET_NEW_IND_PROJECTED_FIN_REPORT: 'api/reports/getNewIndividualProjectedFinancialReport/',
  GET_GRIEVANCE_SUBJECT: 'api/master/getGrievanceSubject',
  GET_FPC_REPAY_TIME_LIST: 'api/master/getRepayTimeFrameList/FPC',
  GET_ONLINE_REPORT_TYPE: 'api/reports/getOnlineReportType/',
  GET_REPAY: 'api/master/getRepayTimeFrameList/',
  GET_CMS_INSTITUTE: 'api/master/getCMSInstitute',
  GET_CONTENT_TYPE: 'api/master/getContentType',
  GET_CMS_DOC_RELATED: 'api/master/getCmsDocRelated',
  GET_LMS_DECISION_LIST: 'api/master/getLMSDecisionList',
  GET_MASTER_TRAINING_LIST: 'api/master/getMasterTrainingList',
  GET_MASTER_PRODUCT_CODE_LIST: 'api/master/getMasterProductCodeList',


  GET_APPLICANT_DOC_LIST: 'api/digitization/getDocumentList/',
  GET_DOCUMENTATION_LIST: 'api/digitization/getDocumentList/',
  GET_DRP_DETAILS_V2: 'api/application/getDrpDetails/v2/',
  GET_APPROVAL_HISTORY: 'api/approval/getApprovalHistory/',
  GET_LOAN_DETAILS: 'api/bankLinkage/getLoanDetails/',
  GET_LOAN_DISBURSEMENT: 'api/bankLinkage/getLoanDisbursementDetails',
  GET_DOCUMENT_DMS: 'api/digitization/getDocumentDMS/',
  GET_REPORT_BY_APPLICANT_ID_VER_NEW: 'api/reports/getOnlineReportType/',
  GET_BANK_LOAN_STATUS_DETAILS: 'api/bankLinkage/getBankLoanStatusDetails/'


}


export const LMS = {
  GET_ALL_COURSE: 'api/lms/getAllCourse',
  SAVE_COURSE: 'api/lms/saveCourse',
  GET_CONTENT: 'api/lms/getContent',
  SAVE_CONTENT: 'api/lms/saveContent',
  DOWNLOAD_CONTENT: 'api/lms/downloadContent',
  GET_COURSES_IF_CONTENT_EXIST: 'api/lms/getCoursesIfContentExist',
  SAVE_DECISION: 'api/lms/saveDecision',
  CONTENT_DOWNLOAD_REQUEST: 'api/lms/contentDownloadRequest',
  SEARCH_TRAINING: 'api/lms/searchTraining',
  ADD_TRAINEE: 'api/lms/addTrainee',
  VIEW_TRAINING: 'api/lms/viewTraining/',
  CANCEL_TRAINEE: 'api/lms/cancelTrainee/',
  SEARCH_MY_TRAINING: 'api/lms/searchTrainee',
}

export const USER = {
  GET_ASSESSMENT_AGENCY_NAME_LIST: 'api/users/getInternalUsers/',
  SEARCH_USER: 'api/users/searchUser/v2'
  
}
