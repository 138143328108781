import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';

const routes: Routes = [
    {
        path: '', redirectTo: '/Home-Page', pathMatch: 'full'
    },

    {
        path: 'Home-Page',
        loadChildren: './modules/main-home-page/main-home-page.module#MainHomePageModule',
        // component: LoginComponent
    },
    {
        path: 'Login',
        data: {preload: false},
        loadChildren: './modules/login/login.module#LoginModule',
        // component: LoginComponent
    },
    {
        path: 'Register-New-User',
        data: {preload: false},
        loadChildren: './modules/register-new-user/register-new-user.module#RegisterNewUserModule',
    },
    {
        path: 'Home',
        data: {preload: false},
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
    },

    {
        path: 'HomePre',
        data: {preload: false},
        loadChildren: './modules/dashboard-pre/dashboard-pre.module#DashboardPreModule',
    },

    {
        path: 'New-User-Change-Password',
        data: {preload: false},
        loadChildren: './modules/new-user-password-change/new-user-password-change.module#NewUserPasswordChangeModule',
    },

    {
        path: 'Apply-Individual',
        data: {preload: false},
        loadChildren: './modules/individual-homepage/individual-homepage.module#IndividualHomepageModule',
    },

    {
        path: 'Basic-Application-Details',
        data: {preload: false},
        loadChildren: './modules/basic-application-details/basic-application-details.module#BasicApplicationDetailsModule',
    },

    {
        path: 'Basic-Application-Details-Section-2',
        data: {preload: false},
        loadChildren: './modules/basic-application-details-sec2/basic-application-details-sec2.module#BasicApplicationDetailsSec2Module',
    },

    {
        path: 'Notification-Details',
        data: {preload: false},
        loadChildren: './modules/bank-quotiation-details/bank-quotiation-details.module#BankQuotiationDetailsModule',
    },

    {
        path: 'View-Application-Status',
        data: {preload: false},
        loadChildren: './modules/basic-application-details-sec2/basic-application-details-sec2.module#BasicApplicationDetailsSec2Module',
    },

    {
        path: 'individual-more-than-onecrore',
        data: {preload: false},
        loadChildren: './modules/individual-more-than-onecrore/individual-more-than-onecrore.module#IndividualMoreThanOnecroreModule',
    },

    {
        path: 'individual-less-than-onecrore',
        data: {preload: false},
        loadChildren: './modules/individual-less-than-onecrore/individual-less-than-onecrore.module#IndividualLessThanOnecroreModule',
    },

    {
        path: 'Guidelines-Instructions',
        data: {preload: false},
        loadChildren: './modules/guidelines-instruction/guidelines-instruction.module#GuidelinesInstructionModule',
    },

    {
        path: 'Data-Input-Sheet-FPC',
        data: {preload: false},
        loadChildren: './modules/data-input-sheet-fpc/data-input-sheet-fpc.module#DataInputSheetFpcModule',
    },


    {
        path: 'Data-Input-Sheet-SHG',
        data: {preload: false},
        loadChildren: './modules/data-input-sheet-shg/data-input-sheet-shg.module#DataInputSheetShgModule',
    },

    {
        path: 'Data-Input-Sheet-Cooperative',
        data: {preload: false},
        loadChildren: './modules/data-input-sheet-cooperative/data-input-sheet-cooperative.module#DataInputSheetCooperativeModule',
    },

    {
        path: 'Grievance',
        data: {preload: false},
        loadChildren: './modules/grievance-view/grievance-view.module#GrievanceViewModule',
    },

    {
        path: 'Common-Infrastructure',
        data: {preload: false},
        loadChildren: './modules/common-infrastructure/common-infrastructure.module#CommonInfrastructureModule',
    },

    {
        path: 'Common-Infrastructure-entity',
        data: {preload: false},
        loadChildren: './modules/common-infrastructure-entity/common-infrastructure-entity.module#CommonInfrastructureEntityModule',
    },

    {
        path: 'Download-Projected-Financial-Report',
        data: {preload: false},
        loadChildren: './modules/download-projected-financial-report-view/download-projected-financial-report-view.module#DownloadProjectedFinancialReportViewModule',
    },
    {
        path: 'Register-New-User2',
        data: {preload: false},
        loadChildren: './modules/register-new-user2/register-new-user2.module#RegisterNewUser2Module',
    },


    {
        path: 'training',
        data: {preload: false},
        loadChildren: './modules/training-view/training-view.module#TrainingViewModule',
    },


    {
        path: 'My-Training',
        data: {preload: false},
        loadChildren: './modules/my-trainings/my-trainings.module#MyTrainingsModule',
    },
    {
        path: 'V-Token',
        data: {preload: false},
        loadChildren: './modules/validate-token/validate-token.module#ValidateTokenModule',
    },
    {
        path: 'Generate-Password',
        data: {preload: false},
        loadChildren: './modules/generate-password/generate-password.module#GeneratePasswordModule',
    },
    {
        path: 'Edp-Domain-Wise-Food',
        data: {preload: false},
        loadChildren: './modules/edp-and-domain-wise-food/edp-and-domain-wise-food.module#EdpAndDomainWiseFoodModule',
    },
    {
        path: 'additional-documents',
        data: {preload: false},
        loadChildren: './modules/upload-additional-documents-view/upload-additional-documents-view.module#UploadAdditionalDocumentsViewModule',
    },
    {
        path: 'update-location',
        data: {preload: false},
        loadChildren: './modules/update-location/update-location.module#UpdateLocationModule',
    },
    // {
    //     path: 'public-dashboard',
    //     data: {preload: false},
    //     loadChildren: './modules/public-dashboard/public-dashboard.module#PublicDashboardModule',
    // },


    {
        path: '**', redirectTo: '/Home-Page', pathMatch: 'full'
    }

];

@NgModule({
    imports: [
        [RouterModule.forRoot(routes, {useHash: true}//{useHash: true}
        )]
    ],
    exports: [RouterModule],
    declarations: [],
})
export class AppRoutingModule {

}
