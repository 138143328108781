import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BaseServiceService } from '../base-service/base-service.service';
import { Http, Headers } from '@angular/http';
import { ServerResponse } from 'src/app/models/server-response'
@Injectable({
  providedIn: 'root'
})
export class UserManagementService extends BaseServiceService {

  constructor(http: HttpClient) {
    super(http);
  }

  authenticate(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/auth';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  authenticateV2(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/v2/auth';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  // registerUser(userAdd) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       let apiName = 'api/users/v2/registerUser';
  //       this.postCall(apiName, userAdd).subscribe((data) => {
  //         if (data != null && data != undefined) {
  //           resolve(data);
  //         } else {
  //           reject();
  //         }
  //       }, (error) => {
  //         reject(error);
  //       })
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  registerUser(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/v3/registerUser';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  updatePassword1(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/updatePassword1';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  updatePassword(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/updatePassword';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  resetPasswordWithOtp(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/resetPasswordWithOtp';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  changePassword(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/changePassword';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  setPassoword (user) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/setPassoword';
        this.postCall(apiName, user).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }


  viewUserProfile() {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/viewUserProfile';
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }


  convergencePatnerCategory() {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/getConvergencePatnerCategory';
        this.getCall(apiName).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }
  
  contactUs(postObj) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/home/contactUs';
        this.postCall(apiName, postObj).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  forgotPassword(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/forgetPassword';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  regWithAadhaar(userAdd) {
    return new Promise((resolve, reject) => {
      try {
        let apiName = 'api/users/regWithAadhaar';
        this.postCall(apiName, userAdd).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      try {
        this.getCall('api/users/logout').subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  confirmAccount(token) {
    return new Promise((resolve, reject) => {
      try {
        this.getCall('api/users/confirmAccount?token=' + token).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  resendLink(reqObj) {
    return new Promise((resolve, reject) => {
      try {
        this.postCall('api/users/resendLink', reqObj).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data,);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  // api/users/verifyUser
  verifyUser(reqObj) {
    return new Promise((resolve, reject) => {
      try {
        this.postCall('api/users/verifyUser', reqObj).subscribe((data) => {
          if (data != null && data != undefined) {
            resolve(data,);
          } else {
            reject();
          }
        }, (error) => {
          reject(error);
        })
      } catch (error) {
        reject(error);
      }
    });
  }

  // api/users/regWithAadhaar

}
