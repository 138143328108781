import {Injectable} from '@angular/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {BaseServiceService} from '../base-service/base-service.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService extends BaseServiceService {

    constructor(http: HttpClient) {
        super(http);
    }

    setItemsArray(formTags, controlName, itemsArray) {
        for (let i = 0; i < formTags.length; i++) {
            if (formTags[i].formControlName == controlName) {
                formTags[i].itemsArray = itemsArray;
                break;
            }
        }
    }


    getStates() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getStates';
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getWhosWhoInfo() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/home/getWhosWhoInfo';
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    // GetShgListByBlockId(body) {
    //     return new Promise((resolve, reject) => {
    //       try {
    //         let apiName = 'api/shg/getShgListByBlockId' ;
    //         this.postCall(apiName,body).subscribe((data) => {
    //           if (data != null && data != undefined) {
    //             resolve(data);
    //           } else {
    //             reject();
    //           }
    //         }, (error) => {
    //           reject(error);
    //         })
    //       } catch (error) {
    //         reject(error);
    //       }
    //     });
    //   }

      GetShgDetails(body) {
        return new Promise((resolve, reject) => {
          try {
            let apiName = 'api/shg/getMemberDetailsByShgCode' ;
            this.postCall(apiName,body).subscribe((data) => {
              if (data != null && data != undefined) {
                resolve(data);
              } else {
                reject();
              }
            }, (error) => {
              reject(error);
            })
          } catch (error) {
            reject(error);
          }
        });
      }



    getDocumentList(applicationId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/digitization/getDocumentList/' + applicationId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }


    getDistricts(stateId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getDistricts' + '/' + stateId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }
    

    getBlock(districtId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getBlocks' + '/' + districtId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getApplicantsCategory() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getApplicantsCategory';
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getAppSubCategory(applicantCategoryId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getAppSubCategory' + '/' + applicantCategoryId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getGender() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getGender';
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getApplicantCast() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getApplicantCast';
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getQualification() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getQualification';
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    uploadDPR(uploadType, file) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/fileUpload/' + uploadType;
                this.postDocument(apiName, file).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    uploadDoc(newObj, file) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/digitization/uploadDocument/' + newObj.applicationId + '/' + newObj.docCode;
                this.postDocument1(apiName, newObj, file).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }


    deleteDocument(documentId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/digitization/deleteDocument/' + documentId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getDocumentType(usedFor, refType) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getDocumentType/' + usedFor + '/' + refType;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getAdditionalDocuments(applicationId, body) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getAdditionalDocuments/' + applicationId;
                this.postCall(apiName, body).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }


    validateOtp(userAdd) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/common/validateOtp';
                this.postCall(apiName, userAdd).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getApplicantsCategoryTraining() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getApplicantsCategory/tnr';
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    updateUser(obj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/users/updateUser';
                this.postCall(apiName, obj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveAdditionalDocuments(reqObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/saveAdditionalDocuments';
                this.postCall(apiName, reqObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    //console.log("logInnerError ", error);
                    reject(error);
                });
            } catch (error) {
                //  console.log("logError ", error);
                reject(error);
            }
        });
    }


    trackApplication(applicationId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/users/getApplicationStatus/' + applicationId;
                this.postCall(apiName, {}).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

}
