export const environment = {
    GA_TRACKING_ID: 'G-7YGE3PMLZ8',
    production: false,

    // ************ For Production ***************


  //baseUrl: 'https://pmfme.mofpi.gov.in/apiTest/'
 baseUrl: 'https://pmfme.mofpi.gov.in/mofpi/'

    // baseUrl: 'https://pmfme.mofpi.gov.in/apiTest/'
    // baseUrl: 'https://pmfme.mofpi.gov.in/mofpi/'


    // ************ For UAT ***************
    // For UAT server
    // baseUrl: 'http://10.153.166.2/mofpi/'
    //  baseUrl: 'http://10.153.166.2/mofpiprod/'

    // For dev server
    // baseUrl: 'http://10.153.166.22/mofpi/'

    // ************ For Local ***************
//  baseUrl:'/mofpi/'

};
