import {Injectable} from '@angular/core';
import {BaseServiceService} from '../base-service/base-service.service';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {APPLICATION_CONTROLLER} from '../../app.constants';
import {MASTER_CONTROLLER} from '../../app.constants';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends BaseServiceService {

    constructor(http: HttpClient) {
        super(http);
    }

    updateStatus(submitObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.UPDATE_STATUS;
                this.postCall(apiName, submitObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getOtherProductType(productType) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = 'api/master/getOdopOtherProduct/' + productType;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveIndExtEnterpriseDetail(submitObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.SAVE_IND_EXT_ENTERPRISE_DETAIL_V2;
                this.postCall(apiName, submitObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveProposal(submitObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.SAVE_PROPOSAL;
                this.postCall(apiName, submitObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveMT1CRFinancialDetails(submitObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.SAVE_MT1CR_FINANCIAL_DETAILS;
                this.postCall(apiName, submitObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveLT1CRFinancialDetails(submitObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.SAVE_LTR1CR_FINANCIAL_DETAILS;
                this.postCall(apiName, submitObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveApplicant(submitObj) {
        return new Promise((resolve, reject) => {
            try {
                // let apiName = APPLICATION_CONTROLLER.SAVE_APPLICANT;
                let apiName = APPLICATION_CONTROLLER.SAVE_APPLICANTV2;
                this.postCall(apiName, submitObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getLandingBankDetails(ifsccode) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.GET_LANDING_BANK_DETAILS + '/' + ifsccode;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveLendingBank(reqObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.SAVE_LENDING_BANK;
                this.postCall(apiName, reqObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getODOPProductDetails(districtCode, productType) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.GET_ODOP_PRODUCT + districtCode + '/' + 'productType/' + productType;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    uploadDoc(newObj, file) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.UPLOAD_DOC + newObj.applicationId + '/' + newObj.docCode + '/' + newObj.refType;
                this.postDocument1(apiName, newObj, file).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }


    getApplicationDetailsStatus() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_APPLICATION;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getApplicantDetails(applicantId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_APPLICANT + applicantId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getExistEnterpriseDetails(applicantId) {
        return new Promise((resolve, reject) => {
            try {
                //let apiName = 'api/application/getExistEnterpriseDetails/' + applicantId;
                let apiName = MASTER_CONTROLLER.GET_EXISTING_DETAILS_V2 + applicantId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }


    getProposedBusinessDetails(applicantId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_PROPOSAL + applicantId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getMt1crExistFinancialDetailsDraft(applicantId) {
        return new Promise((resolve, reject) => {
            try {
                //let apiName = 'api/application/getMt1crExistFinancialDetailsDraft/' + applicantId;
                let apiName = MASTER_CONTROLLER.GET_MT1_EXISTING_FINANCIAL_DETAILS + applicantId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getLt1crExistFinancialDetailsDraft(applicantId) {
        return new Promise((resolve, reject) => {
            try {
                //let apiName = 'api/application/getLt1crExistFinancialDetailsDraft/' + applicantId;
                let apiName = MASTER_CONTROLLER.GET_LT1_EXISTING_FINANCIAL_DETAILS + applicantId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getLendingBankDetails(applicantId) {
        return new Promise((resolve, reject) => {
            try {
                //let apiName = 'api/application/getLendingBankDetails/' + applicantId;
                let apiName = MASTER_CONTROLLER.GET_LENDING_BANK_DETAILS + applicantId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getProjectedFin(applicantId) {
        return new Promise((resolve, reject) => {
            try {
                //let apiName = 'api/application/getLendingBankDetails/' + applicantId;
                let apiName = APPLICATION_CONTROLLER.GET_PROJECTED_FIN + applicantId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getApplicationDocuments(applicantID) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_APPLICANT_DOC_LIST + applicantID;
                this.getViewDoc(apiName).subscribe((data) => {
                    // console.log("logData ", data);
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    // console.log("logInnerError ", error);
                    reject(error);
                })
            } catch (error) {
                // console.log("logError ", error);
                reject(error);
            }
        });
    }

    getDocumentList(request) {
        return new Promise((resolve, reject) => {
            try {
                this.getCall(MASTER_CONTROLLER.GET_DOCUMENTATION_LIST + request).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getDrpDetailsV2(applicationId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_DRP_DETAILS_V2 + applicationId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getApprovalHistory(applicationId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_APPROVAL_HISTORY + applicationId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getLoanDetails(request) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_LOAN_DETAILS + request;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    // console.log("logInnerError ", error);
                    reject(error);
                })
            } catch (error) {
                //console.log("logError ", error);
                reject(error);
            }
        });
    }

    getLoanDisbursement(applicationId) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_LOAN_DISBURSEMENT + '/' + applicationId;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    getBankLoanStatusDetails(applicationNo) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_BANK_LOAN_STATUS_DETAILS + applicationNo;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    getDocumentDMS(request) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_DOCUMENT_DMS + request;
                this.getViewDoc(apiName).subscribe((data) => {
                    //console.log("logData ", data);
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    // console.log("logInnerError ", error);
                    reject(error);
                })
            } catch (error) {
                // console.log("logError ", error);
                reject(error);
            }
        });
    }

    getReportByApplicantIdVersionNew(applicantID) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_REPORT_BY_APPLICANT_ID_VER_NEW + applicantID;
                this.getViewDoc(apiName).subscribe((data) => {
                    // console.log("logData ", data);
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    // console.log("logInnerError ", error);
                    reject(error);
                })
            } catch (error) {
                // console.log("logError ", error);
                reject(error);
            }
        });
    }

    getSanctionedApplications(body) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_SANCTIONED_APPLICATIONS;
                this.postCall(apiName, body).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    getUpdatedLatLong() {
        return new Promise((resolve, reject) => {
            try {
                let apiName = MASTER_CONTROLLER.GET_UPDATED_LOCATION;
                this.getCall(apiName).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }

    saveLatLong(reqObj) {
        return new Promise((resolve, reject) => {
            try {
                let apiName = APPLICATION_CONTROLLER.SAVE_LAT_LONG;
                this.postCall(apiName, reqObj).subscribe((data) => {
                    if (data != null && data != undefined) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, (error) => {
                    reject(error);
                })
            } catch (error) {
                reject(error);
            }
        });
    }


}
